<script>
import debounce from 'lodash/debounce'
import BaseAutocomplete from '../base-autocomplete/BaseAutocomplete'

export default {
    name: 'LazyAutocomplete',

    extends: BaseAutocomplete,

    data() {
        return {
            processQuery: debounce((typed) => {
                if (
                    this.innerSelectedItem &&
                    typed !== this.getItemLabel(this.innerSelectedItem)
                ) {
                    this.innerSelectedItem = null
                }

                if (typed === null || typed === undefined) {
                    this.results = null
                    return
                }

                const query = isNaN(typed)
                    ? typed.toString().toLowerCase()
                    : typed

                if (this.resultsCache[query]) {
                    this.results = this.resultsCache[query]
                    return
                }

                this.loading = true
                this.$emit('query-change', query)
            }, 300),
        }
    },

    watch: {
        typed(to) {
            this.processQuery(to)
        },

        value: {
            immediate: true,
            handler(to) {
                if (!this.innerSelectedItem && !isNaN(to)) {
                    this.processQuery(to)
                }
            },
        },

        innerSelectedItem(to) {
            if (!to && !isNaN(this.value)) {
                this.processQuery(this.value)
            }
        },
    },
}
</script>
