<template>
    <div class="d-flex flex-align-center">
        <i v-if="icon" :class="icon" />
        <span>
            <span>{{ title }}</span>
            <template v-if="subtitle">
                <br />
                <small class="text-secondary">
                    {{ subtitle }}
                </small>
            </template>
        </span>
        <i v-if="secondaryIcon" :class="secondaryIcon" class="text-secondary text-small ml-auto" />
    </div>
</template>

<script>
    export default {
        name: 'InputListItemContent',

        props: {
            value: [Number, String, Object],
            title: [String, Number],
            subtitle: [String, Number],
            icon: String,
            secondaryIcon: String,
        },
    };
</script>
