export const getOrderStatusColor = (order) => {
    if ([4, 5, 16, 44, 49, 53].indexOf(order.state) !== -1) {
        return 'success';
    } else if (
        [20, 25, 26, 27, 30, 31, 32, 39, 47, 48, 51, 52, 57, 70].indexOf(order.state) !== -1
    ) {
        return 'danger';
    } else {
        return 'warning';
    }
};
