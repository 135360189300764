<template>
    <div>
        <div :class="{ 'line-clamp-3': !expand }">
            <slot />
        </div>
        <p class="text-medium text-right">
            <a class="text-primary cursor-pointer" @click="expand = !expand">
                <span>
                    {{ expand ? 'Ver menos' : 'Ver mais' }}
                    <i
                        :class="{
                            'icon-chevron-up': expand,
                            'icon-chevron-down': !expand,
                        }"
                        class="icon-cr"
                    />
                </span>
            </a>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'CollapsedContent',

        data() {
            return {
                expand: false,
            };
        },
    };
</script>
