// Formata o tputlo da rota substituindo nome dos parâmetros pelo valor
const titleFormatter = (crumbTitle, params) => {
    let title = crumbTitle;

    for (const paramName in params) {
        if (params.hasOwnProperty(paramName)) {
            title = title.replace(`:${paramName}`, params[paramName]);
        }
    }

    return title;
};

export default titleFormatter;
