export default (routes) => {
    // Identifica a "hierarquia" de uma rota comparando as "partes" da url
    // compara sempre removendo a "/" do início
    const makeCrumbs = (route, routes) => {
        const crumbsPaths = route.path.replace(/^\//g, '').split('/');

        const crumbs = [];
        for (let i = 0; i < crumbsPaths.length; i++) {
            const partialPath = crumbsPaths.slice(0, i).join('/');

            const crumbRoute = routes.find(
                (r) => r !== route && r.path.replace(/^\//g, '') === partialPath
            );

            if (crumbRoute && crumbRoute.name) {
                crumbs.push({
                    title: (crumbRoute.meta || {}).title,
                    name: crumbRoute.name,
                });
            }
        }

        return crumbs;
    };

    routes
        .filter((r) => !r.redirect) // rotas com redirect não precisam de breadcrumbs
        .forEach((r) => {
            r.meta.breadcrumbs.push(...makeCrumbs(r, routes));

            if (r.children) {
                r.children
                    .filter((c) => !c.redirect)
                    .forEach((c) => {
                        // as rotas filhas obviamente têm os breadcrumbs da rota mãe
                        c.meta.breadcrumbs.push(...r.meta.breadcrumbs);

                        // adiciona a rota mãe como breadcrumb
                        if (r.name) {
                            c.meta.breadcrumbs.push({
                                title: (r || r.meta).title,
                                name: r.name,
                            });
                        }

                        // procura outras rotas dentre as irmãs
                        c.meta.breadcrumbs.push(...makeCrumbs(c, r.children));
                    });
            }
        });
};
