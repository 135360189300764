<template>
    <Modal :title="title" :value="show" class="alert" @dismiss="$emit('no')">
        <template v-slot:title>
            <slot name="title" />
        </template>
        <div class="card-block">
            <div :class="{ opened: !loading }" class="collapsible">
                <slot></slot>
            </div>
            <div :class="{ opened: loading }" class="collapsible">
                <div class="d-flex flex-spaced-fixed">
                    <div class="cr-spinner-small"></div>
                    <p class="flex-child-fixed">Aguarde...</p>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="card-block">
            <button class="btn btn-light" @click="$emit('no')">
                {{ negativeLabel }}
            </button>
            <button :class="{ 'btn-danger': dangerous }" class="btn" @click="$emit('yes')">
                {{ positiveLabel }}
            </button>
        </div>
    </Modal>
</template>

<script>
    import Modal from '../modal/Modal';

    export default {
        name: 'ConfirmationModal',

        components: { Modal },

        props: {
            show: {
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            positiveLabel: {
                type: String,
                default: 'Sim',
            },
            negativeLabel: {
                type: String,
                default: 'Não',
            },
            title: String,
            dangerous: Boolean,
        },
    };
</script>
