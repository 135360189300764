<script>
import debounce from 'lodash/debounce'
import BaseAutocomplete from '../base-autocomplete/BaseAutocomplete'

export default {
    name: 'EagerAutocomplete',

    extends: BaseAutocomplete,

    data() {
        return {
            originalItems: this.items,
        }
    },

    watch: {
        typed(newVal) {
            this.filter(newVal)
        },

        items(newItems) {
            this.originalItems = newItems
        },
    },

    methods: {
        filter: debounce(function (query) {
            if (!this.originalItems) return
            this.loading = true
            query = (query || '').toString().trim().toLowerCase()
            if (query.length === 0) {
                this.updateResults([...this.originalItems])
            } else {
                this.updateResults(
                    this.originalItems.filter((item) =>
                        this.compareItem(item, query)
                    )
                )
            }
        }, 300),

        compareItem(item, query) {
            return (
                this.getItemLabel(item)
                    .toString()
                    .toLowerCase()
                    .indexOf(query) >= 0
            )
        },
    },
}
</script>
