<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'Alert',

        props: {
            color: {
                type: String,
                required: true,
                validator: function (value) {
                    return (
                        ['default', 'primary', 'info', 'success', 'warning', 'danger'].indexOf(
                            value
                        ) !== -1
                    );
                },
            },
        },

        computed: {
            classes() {
                return `alert-${this.color}`;
            },
        },
    };
</script>
