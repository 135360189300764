<template>
    <div class="form-group">
        <input
            :id="innerId"
            :checked="isChecked"
            :disabled="disabled"
            :name="name"
            class="cr-switch"
            type="checkbox"
            @change="changed($event)"
        />
        <label :for="innerId">{{ label }}</label>
    </div>
</template>

<script>
import Checkbox from '../checkbox/Checkbox'

export default {
    name: 'CrSwitch',
    extends: Checkbox,
}
</script>
