import { EventBus } from './common';
import Vue from './vue-common';
import NotificationsManager from '../components/common/notification/NotificationsManager';
import VueRouter from 'vue-router';

// Adiciona Vue Router
Vue.use(VueRouter);

// Notification
new Vue({
    render: (h) => h(NotificationsManager),
}).$mount('#notifications-vue');

/**
 * Mostra gritter no canto direito da tela com os parâmetros informados.
 * @param success
 * @param title Título.
 * @param text Descrição.
 */
window.displayMessage = function (success, title, text) {
    EventBus.$emit('push-notification', title, text, success === true ? 'success' : 'danger');
};

/**
 * Mostra gritter no canto direito da tela com os parâmetros informados.
 * @param type
 * @param title Título.
 * @param text Descrição.
 */
window.displayGrowl = function (type, title, text) {
    EventBus.$emit('push-notification', title, text, type);
};

/**
 * Mostra gritter de sucesso no canto direito da tela com os parâmetros informados.
 * @param title Título.
 * @param text Descrição.
 */
window.displayGrowlSuccess = function (title, text) {
    displayGrowl('success', title, text);
};

/**
 * Mostra gritter de erro no canto direito da tela com os parâmetros informados.
 * @param title Título.
 * @param text Descrição.
 */
window.displayGrowlDanger = function (title, text) {
    displayGrowl('danger', title, text);
};

/**
 * Mostra gritter de erro no canto direito da tela com os parâmetros informados.
 * @param title Título.
 * @param text Descrição.
 */
window.displayGrowlWarning = function (title, text) {
    displayGrowl('warning', title, text);
};

/**
 * Mostra gritter de erro no canto direito da tela com os parâmetros informados.
 * @param title Título.
 * @param text Descrição.
 */
window.displayGrowlInfo = function (title, text) {
    displayGrowl('info', title, text);
};
