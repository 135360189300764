<template>
    <FieldWrapper
        :id="id"
        :label="label"
        :tooltip="tooltip"
        :hint="hint"
        :error="error"
        :add-on-before="addOnBefore"
        :add-on-after="addOnAfter"
    >
        <div class="rich-text-field">
            <EditorMenuBar
                v-if="showBar"
                class="d-flex bg-default-4 rounded mb-1 text-default-1 overflow-hidden"
                :editor="editor"
                v-slot="{ commands, isActive, getMarkAttrs, menu }"
            >
                <RichTextFieldBar
                    :allow-link="allowLink"
                    :allow-headings="allowHeadings"
                    :commands="commands"
                    :isActive="isActive"
                    :getMarkAttrs="getMarkAttrs"
                    :menu="menu"
                    :show-link="linkMenuIsActive"
                    :link-url.sync="linkUrl"
                    @set-link="setLinkUrl(commands.link, linkUrl)"
                    @reset-link="setLinkUrl(commands.link, null)"
                    @show-link="showLinkMenu(getMarkAttrs('link'))"
                    @hide-link="hideLinkMenu"
                />
            </EditorMenuBar>
            <EditorMenuBubble
                v-if="showBubble"
                :editor="editor"
                :keep-in-bounds="keepInBounds"
                v-slot="{ commands, isActive, getMarkAttrs, menu }"
            >
                <RichTextFieldBar
                    class="rich-text-field-bubble"
                    :class="{ 'is-active': menu.isActive }"
                    :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                    :allow-link="allowLink"
                    :commands="commands"
                    :isActive="isActive"
                    :getMarkAttrs="getMarkAttrs"
                    :menu="menu"
                    :show-link="linkMenuIsActive"
                    :link-url.sync="linkUrl"
                    @set-link="setLinkUrl(commands.link, linkUrl)"
                    @reset-link="setLinkUrl(commands.link, null)"
                    @show-link="showLinkMenu(getMarkAttrs('link'))"
                    @hide-link="hideLinkMenu"
                />
            </EditorMenuBubble>

            <EditorContent
                data-testid="rich-text-field-content"
                class="rich-text-field-content"
                :class="{ 'border-danger': error }"
                :editor="editor"
            />
        </div>
    </FieldWrapper>
</template>

<script>
    import { Editor, EditorContent, EditorMenuBubble, EditorMenuBar } from 'tiptap';
    import { Bold, Italic, History, Link, Heading } from 'tiptap-extensions';
    import { fieldWrapperProps } from '../../../helpers/input';
    import FieldWrapper from '../field-wrapper/FieldWrapper';
    import RichTextFieldBar from '../rich-text-field-bar/RichTextFieldBar';

    export default {
        name: 'RichTextField',

        components: {
            RichTextFieldBar,
            FieldWrapper,
            EditorContent,
            EditorMenuBubble,
            EditorMenuBar,
        },

        props: {
            value: {
                type: String,
                default: '',
            },
            showBar: Boolean,
            showBubble: {
                type: Boolean,
                default: true,
            },
            allowLink: Boolean,
            allowHeadings: Boolean,

            autoCleanErrors: {
                type: Boolean,
                default: true,
            },

            ...fieldWrapperProps,
        },

        data() {
            const extensions = [new Bold(), new Italic(), new History()];

            if (this.allowLink) {
                extensions.push(new Link());
            }

            if (this.allowHeadings) {
                extensions.push(
                    new Heading({
                        levels: [1, 2, 3],
                    })
                );
            }

            return {
                keepInBounds: true,
                lastEmittedContent: null,
                editor: new Editor({
                    extensions,
                    content: this.value,
                    onBlur: () => {
                        this.lastEmittedContent = this.editor.getHTML();

                        if (this.error && this.autoCleanErrors) {
                            this.$emit('update:error', null);
                        }

                        this.$emit('input', this.lastEmittedContent);
                    },
                }),
                linkUrl: null,
                linkMenuIsActive: false,
            };
        },

        watch: {
            value(to) {
                this.editor.setContent(to);
            },
        },

        beforeDestroy() {
            this.editor.destroy();
        },

        methods: {
            showLinkMenu(attrs) {
                this.linkUrl = attrs.href;
                this.linkMenuIsActive = true;
            },
            hideLinkMenu() {
                this.linkUrl = null;
                this.linkMenuIsActive = false;
            },
            setLinkUrl(command, url) {
                command({ href: url });
                this.hideLinkMenu();
            },
        },
    };
</script>
