import Vue from 'vue';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import cr$ from './crquery';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

export * from './lazyload';

//region RequestIdleCallback Polyfill
window.requestIdleCallback =
    window.requestIdleCallback ||
    function (cb) {
        let start = Date.now();
        return setTimeout(function () {
            cb({
                didTimeout: false,
                timeRemaining: function () {
                    return Math.max(0, 50 - (Date.now() - start));
                },
            });
        }, 1);
    };
//endregion

//region Scope & Cookie
const _getScope = function (name) {
    return !window.initialScope || typeof window.initialScope[name] === 'undefined'
        ? null
        : window.initialScope[name];
};
export let getScope = _getScope;

const _readCookie = function (name, defaultValue = null) {
    let nameEQ = name + '=';
    let ca = [];
    if (document && document.cookie) {
        let cookie = document.cookie + '';
        ca = cookie.split(';');
    }
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            const value = c.substring(nameEQ.length, c.length);
            return value || defaultValue;
        }
    }
    return defaultValue;
};

export let readCookie = _readCookie;

export let setCookie = function (name, value, expiration, path, domain) {
    if (typeof expiration !== 'undefined' && expiration === -1) {
        expiration = 'Thu, 01 Jan 1970 00:00:00 UTC';
    }

    expiration = typeof expiration !== 'undefined' && expiration ? `; expires=${expiration}` : '';
    path = typeof path !== 'undefined' && path ? `; path=${path}` : '; path=/';
    domain =
        typeof domain !== 'undefined' && domain
            ? `; domain=${domain}`
            : '; domain=.' + window.location.hostname;

    document.cookie = `${name}=${value}${expiration}${path}${domain}`;
};
//endregion

//region Bugsnag
const bugsnagClient = Bugsnag.start({
    apiKey: '6c62953794071a67fcb0de2bb44ebba4',
    releaseStage: _getScope('APP_ENV') || 'production',
    plugins: [new BugsnagPluginVue(Vue)],
});

const customer = _getScope('CUSTOMER');
if (customer && customer['ID']) {
    bugsnagClient.setUser(customer.ID, customer.EMAIL, customer.NAME);
}
//endregion

//region DayJS
dayjs.locale('pt-br');
export let timeHandler = dayjs;
//endregion

//region Event Bus
export const EventBus = new Vue();
//endregion

//region Axios
export let http = axios.create({
    baseURL: _getScope('URL'),
});

http.interceptors.request.use(
    function (config) {
        // Adiciona ID do idioma
        config.params = config.params || {};

        // Adiciona ID do grupo padrão do usuário
        if (_getScope('default_group_id')) {
            config.params.defaultGroup = _getScope('default_group_id');
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export let api = axios.create({
    baseURL: _getScope('API_HOST'),
});

api.interceptors.request.use(
    function (config) {
        // Adiciona ID do idioma
        config.params = config.params || {};

        // Adiciona ID do grupo padrão do usuário
        if (_getScope('default_group_id')) {
            config.params.defaultGroup = _getScope('default_group_id');
        }

        config.headers = config.headers || {};

        if (_getScope('TENANT_SESSION')) {
            config.headers['Cr-Api-Auth'] = _readCookie(_getScope('TENANT_SESSION'));
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
//endregion

//region Data Layer
export let addDataLayer = function (
    customerId,
    email,
    hashEmail,
    userName,
    userAvatar,
    reamazeAuthKey,
    idDefaultGroup
) {
    window.dataLayer = window.dataLayer || [];
    let hashEmailDl = dataLayer[0].HashedEmail;

    if (
        (typeof hashEmailDl === 'undefined' || hashEmailDl === '') &&
        hashEmail &&
        hashEmail !== ''
    ) {
        dataLayer.push({
            HashedEmail: decodeURIComponent(hashEmail),
            'user-id': customerId,
            'user-email': decodeURIComponent(email),
            'user-name': decodeURIComponent(userName ? userName.replace('+', ' ') : ''),
            'user-avatar': 'https://uma-penca.imgix.net/' + decodeURIComponent(userAvatar),
            'reamaze-authkey': reamazeAuthKey,
            'id-default-group': idDefaultGroup,
        });
    }
};
//endregion

//region Helpers
const _resizeOrientationAware = function (cb, initCall = false) {
    let lastResize = null;
    const cbEncapsulate = () => {
        if (lastResize && new Date().getTime() - lastResize <= 300) return;
        lastResize = new Date().getTime();

        window.requestIdleCallback(cb);
    };

    window.addEventListener('orientationchange', cbEncapsulate);
    window.addEventListener('resize', cbEncapsulate);

    if (initCall) cbEncapsulate();
};

export let resizeOrientationAware = _resizeOrientationAware;

export let windowSizeCb = function (biggerCb, smallerCb, breakpoint = 768, onlyOnce = true) {
    let biggerCount = 0;
    let smallerCount = 0;

    _resizeOrientationAware(() => {
        if (window.innerWidth >= breakpoint && (!onlyOnce || biggerCount === 0)) {
            biggerCount++;
            if (biggerCb && typeof biggerCb === 'function') biggerCb();
        } else if (window.innerWidth < breakpoint && (!onlyOnce || smallerCount === 0)) {
            smallerCount++;
            if (smallerCb && typeof smallerCb === 'function') smallerCb();
        }
    }, true);
};

export let cloneElementsToFitPage = function (container, multiply = 1) {
    const cloneElem = function (elem) {
        let clone = document.createElement('div');
        clone.innerHTML = elem.outerHTML;
        return clone.childNodes[0];
    };

    let contentWidth = 0;
    container.children().each((el) => {
        contentWidth += el.offsetWidth;
    });

    const originalBoxes = container.children().raw;

    let i = 0;
    const addClone = () => {
        if (contentWidth < window.innerWidth * multiply) {
            const elemToAppend = cloneElem(originalBoxes[i]);
            elemToAppend.classList.add('clone');
            container.append(elemToAppend);

            let timeout;
            timeout = setInterval(() => {
                if (elemToAppend.offsetWidth > 0) {
                    contentWidth += elemToAppend.offsetWidth;
                    i = i === originalBoxes.length - 1 ? 0 : i + 1;
                    clearInterval(timeout);
                    addClone();
                }
            }, 50);
        }
    };

    addClone();
};

export let readUrlQuery = function (name) {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export let loadScriptAsync = (url) => {
    return new Promise((resolve, reject) => {
        let tag = document.createElement('script');
        tag.src = url;
        tag.async = true;
        tag.onload = resolve;
        tag.onerror = reject;
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    });
};

export const objectPropertyComparator = (propName, reverse) => (a, b) => {
    if (a[propName] < b[propName]) return reverse ? 1 : -1;
    if (a[propName] > b[propName]) return reverse ? -1 : 1;
    return 0;
};

export const copyToClipboard = (text) => {
    const el = document.createElement('textarea');
    el.value = text;
    el.contentEditable = true;
    el.readOnly = true;
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    document.body.appendChild(el);

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        const range = document.createRange();
        range.selectNodeContents(el);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        el.setSelectionRange(0, 999999);
    } else {
        el.select();
    }

    document.execCommand('copy');
    document.body.removeChild(el);
};

// não utilize arrow function aqui
export const reamazeClickHandler = function () {
    if (typeof window.Reamaze !== 'undefined') {
        window.Reamaze.popup();
    } else {
        const textElement = cr$.byEl(this).children('p');
        let textContent;

        if (textElement) {
            textContent = textElement.html();
            textElement.html('Carregando...');
        }

        loadScriptAsync('https://cdn.reamaze.com/assets/reamaze.js')
            .then(() => {
                const openPopUp = setInterval(() => {
                    if (typeof window.Reamaze !== 'undefined' && window._rmzLoaded) {
                        clearInterval(openPopUp);
                        window.Reamaze.popup();
                        setTimeout(() => window.Reamaze.popup(), 600);
                    }
                }, 10);
            })
            .catch(() => {})
            .then(() => {
                if (textElement) {
                    textElement.html(textContent);
                }
            });
    }
};

export const scrollToPosition = (x, y, animationTime, container) => {
    if (x === undefined || y === undefined) return;

    let startX, startY;

    if (!container) {
        startX = document.body.scrollLeft || document.documentElement.scrollLeft;
        startY = document.body.scrollTop || document.documentElement.scrollTop;
    } else {
        startX = container.scrollLeft;
        startY = container.scrollTop;
    }

    const isGoingRight = startX > x;
    const isGoingUp = startY > y;
    const diffX = Math.abs(startX - x);
    const diffY = Math.abs(startY - y);
    const startTime = new Date().getTime();

    const animation = () => {
        const elapse = new Date().getTime() - startTime;
        const percentage = Math.min(elapse / animationTime, 1);

        const scrollX = isGoingRight ? startX - diffX * percentage : startX + diffX * percentage;
        const scrollY = isGoingUp ? startY - diffY * percentage : startY + diffY * percentage;

        if (!container) {
            window.scrollTo(scrollX, scrollY);
        } else if (container.scrollTo) {
            container.scrollTo(scrollX, scrollY);
        } else {
            // Edge, Safari
            container.scrollLeft = isGoingRight
                ? startX - diffX * percentage
                : startX + diffX * percentage;
            container.scrollTop = isGoingUp
                ? startY - diffY * percentage
                : startY + diffY * percentage;
        }

        if (elapse < animationTime) window.requestAnimationFrame(animation);
    };

    window.requestAnimationFrame(animation);
};

const _appendQueryString = (url, queries = {}) => {
    if (!url) {
        return null;
    }

    url = new URL(url);

    for (let query in queries) {
        if (!queries.hasOwnProperty(query)) continue;

        const queryValue = queries[query];
        if (queryValue !== null && queryValue !== undefined) {
            url.searchParams.set(query, queryValue);
        }
    }

    return url.toString();
};

// todo ver se tem um lugar melhor pra essa função
export const getProductThumbLink = (
    fallback,
    thumb_png,
    fabric_id,
    append = {},
    preferFallback = false
) => {
    if (fabric_id && (!preferFallback || !fallback)) {
        const fabricBg = (_getScope('FABRICS_BG') || [])[fabric_id];

        if (thumb_png && fabricBg && fabricBg[0] === '#') {
            return _appendQueryString(thumb_png, {
                bg: fabricBg.replace('#', ''),
                ...append,
            });
        } else if (thumb_png && fabricBg) {
            return _appendQueryString(`${_getScope('CDN_SHARED')}/${fabricBg}`, {
                'mark-alpha': 90,
                'mark-scale': '80',
                'mark-align': 'middle,center',
                mark: thumb_png,
                ...append,
            });
        }
    }

    if (fallback) {
        return _appendQueryString(fallback, append);
    }

    return null;
};

export const appendQueryString = _appendQueryString;
//endregion

export const getDeepProp = (obj, path) => {
    if (path.indexOf('.') > 0) {
        const propertyChain = path.split('.');

        let propertyValue = { ...obj };
        while (propertyValue && propertyChain.length > 0) {
            propertyValue = propertyValue[propertyChain.shift()];
        }

        return propertyValue;
    } else {
        return obj[path];
    }
};

export const getOrderStatusColor = (order) => {
    if ([4, 5, 16, 44, 49, 53].indexOf(order.state) !== -1) {
        return 'success';
    } else if (
        [20, 25, 26, 27, 30, 31, 32, 39, 47, 48, 51, 52, 57, 70].indexOf(order.state) !== -1
    ) {
        return 'danger';
    } else {
        return 'warning';
    }
};

export const roundFloat = (number, precision = 2) => {
    const factor = Math.pow(10, precision);
    const tempNumber = number * factor;
    const roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
};

// normaliza, remove acentos, remove caracteres espeicais, coloca em minúsculas
// útil pra links e comparações em buscas
export const getSimplifiedText = (text) =>
    !text
        ? ''
        : text
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/[^\w\s]/gi, '');
