export const SHOW_MOBILE_MENU_EVENT = 'show-header-mobile-menu';
export const CLOSE_DESKTOP_MENU_EVENT = 'close-header-desktop-menu';

export const getOptimizedEventName = (event, el) => {
    const subject = el || window;
    const customName = `optimized-${event}`;
    let isRunning = false;

    const handler = () => {
        if (isRunning) return;
        isRunning = true;
        requestAnimationFrame(function () {
            subject.dispatchEvent(new CustomEvent(customName));
            isRunning = false;
        });
    };

    subject.addEventListener(event, handler);
    return customName;
};
