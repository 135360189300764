<template>
    <div
        :class="{ 'form-group': !!label || !!hint || showErrorMessage }"
        class="pos-relative"
    >
        <label v-if="label || hint" :for="id">
            {{ formattedLabel }}

            <span
                v-if="tooltip"
                v-tooltip="tooltip"
                class="fas fa-info-circle ml-1"
            />

            <br v-if="label && hint" />

            <small v-if="hint" class="text-secondary">
                {{ hint }}
            </small>
        </label>

        <div v-if="addOnBefore || addOnAfter" class="input-group">
            <span v-if="addOnBefore">
                {{ addOnBefore }}
            </span>
            <slot />
            <span v-if="addOnAfter">
                {{ addOnAfter }}
            </span>
        </div>
        <template v-else>
            <slot />
        </template>

        <small v-if="showErrorMessage" class="text-danger">
            {{ error }}
        </small>
    </div>
</template>

<script>
import { fieldWrapperProps } from '../../../helpers/input'

export default {
    name: 'FieldWrapper',

    props: { ...fieldWrapperProps },

    computed: {
        formattedLabel() {
            if (!this.label) return null
            return this.label.trim().indexOf(':') > 0
                ? this.label
                : `${this.label}:`
        },

        showErrorMessage() {
            return this.error && typeof this.error === 'string'
        },
    },
}
</script>
