<script>
    export default {
        name: 'CollapsibleGroupItem',

        inject: {
            collapsibleGroup: {
                default: null,
            },
        },

        props: {
            startOpen: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return { openByItself: this.startOpen };
        },

        mounted() {
            if (this.collapsibleGroup) {
                this.collapsibleGroup.registerBlock(this);

                if (this.startOpen) {
                    this.collapsibleGroup.setActiveBlock(this);
                }
            }
        },

        computed: {
            open() {
                return this.collapsibleGroup ? this.openByGroup : this.openByItself;
            },

            openByGroup() {
                return this.collapsibleGroup.activeBlock() === this;
            },
        },

        methods: {
            toggle() {
                if (this.collapsibleGroup) {
                    this.collapsibleGroup.setActiveBlock(this.openByGroup ? null : this);
                } else {
                    this.openByItself = !this.openByItself;
                }
            },
        },
    };
</script>
