export const resizeOrientationAware = function (cb, initCall = false) {
    let lastResize = null;
    const cbEncapsulate = () => {
        if (lastResize && new Date().getTime() - lastResize <= 300) return;
        lastResize = new Date().getTime();

        window.requestIdleCallback(cb);
    };

    window.addEventListener('orientationchange', cbEncapsulate);
    window.addEventListener('resize', cbEncapsulate);

    if (initCall) cbEncapsulate();
};

export const readCookie = function (name, defaultValue = null) {
    let nameEQ = name + '=';
    let ca = [];
    if (document && document.cookie) {
        let cookie = document.cookie + '';
        ca = cookie.split(';');
    }
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            const value = c.substring(nameEQ.length, c.length);
            return value || defaultValue;
        }
    }
    return defaultValue;
};
