<template>
    <div :class="{ opened: modelValue }" class="cr-modal" @mousedown="onModalClick($event)">
        <div class="cr-modal-dialog">
            <Scrollable class="card overflow-hidden shadow-5">
                <template v-if="$slots.title" v-slot:fixed-top>
                    <slot name="header">
                        <div :class="{ 'card-toolbar': showCloseBtn }" class="card-block">
                            <span class="h5 card-title"><slot name="title" /></span>
                            <span
                                v-if="showCloseBtn"
                                aria-label="Fechar"
                                class="fas fa-times cursor-pointer"
                                @click.prevent="dismiss"
                            />
                        </div>
                    </slot>
                    <slot name="fixed-top" />
                </template>
                <slot />
                <template v-slot:fixed-bottom>
                    <slot name="fixed-bottom" />
                </template>
            </Scrollable>
        </div>
    </div>
</template>

<script>
    import Scrollable from '../scrollable/Scrollable';

    export default {
        compatConfig: { COMPONENT_V_MODEL: false },

        name: 'VueModal',

        components: { Scrollable },

        model: {
            prop: 'modelValue',
            event: 'update:modelValue',
        },

        props: {
            showCloseBtn: {
                type: Boolean,
                default: true,
            },
            modelValue: {
                type: [Boolean, Object],
                default: false,
            },
            allowDismiss: {
                type: Boolean,
                default: true,
            },
            allowOutClickToDismiss: {
                type: Boolean,
                default: true,
            },
        },

        created() {
            window.addEventListener('keyup', this.onEsc);
        },

        beforeUnmount() {
            this.beforeUnmount();
        },

        // Vue 2
        beforeDestroy() {
            this.beforeUnmount();
        },

        methods: {
            dismiss() {
                if (!this.allowDismiss) return;

                this.$emit('dismiss');
                this.$emit('update:modelValue', this.modelValue instanceof Object ? null : false);
            },

            onModalClick(event) {
                if (
                    event.target.classList.contains('cr-modal') ||
                    event.target.classList.contains('cr-modal-dialog')
                ) {
                    event.preventDefault();
                    event.stopPropagation();
                    if (this.modelValue && this.allowOutClickToDismiss) {
                        this.dismiss();
                    }
                }
            },

            onEsc(event) {
                if (this.modelValue && event.keyCode === 27) {
                    this.dismiss();
                }
            },

            beforeUnmount() {
                window.removeEventListener('keyup', this.onEsc);
            },
        },
    };
</script>
