export default () => ({
    pt: {
        _cart: {
            unableToLoadDataLogin:
                'Não foi possível carregar os dados. Se você já é cadastrado, faça login para encontrarmos sua compra.',
            unableToLoadPurchase: 'Não foi possível carregar os dados da sua compra.',
            ideasToChangeStatus: 'Algumas ideias para você mudar essa situação:',
            addWrappingHere: 'Inclua embalagens clicando aqui',
            addWrappingCardsHere: 'Inclua embalagens e cartões de presente clicando aqui',
            collectingLoyaltyPointsX:
                'Você está acumulando {n} ponto no cartão fidelidade! Ao acumular 10 pontos, você ganha R$50 em compras. | Você está acumulando {n} pontos no cartão fidelidade! Ao acumular 10 pontos, você ganha R$50 em compras.',
            summary: 'Resumo',
            appliedDiscounts: 'Cupons utilizados',
            onlyOrdersAboveX: 'Apenas em pedidos acima de {n}',
            enterCustomName: 'Insira o nome personalizado',
            giftOptions: 'Opções para presente',
            selectGiftCardsAndAdd:
                'Selecione os cartões desejados e os adicione no botão ao final:',
            selectGiftCardsWrappingsAndAdd:
                'Selecione os cartões e embalagem desejados e os adicione no botão ao final:',
            paperWrappingWithDimensionsX: 'Embalagem de papel com as dimensões de {0} x {1}cm.',
            canvasWrappingWithDimensionsX:
                'Embalagem de {0} x {1}cm feita em lona, com estampa em serigrafia.',
            onlyXWrappingPerProduct:
                'Só é permitida a compra de {n} embalagem por produto. | Só é permitida a compra de {n} embalagens por produto.',
            card: 'Cartão',
            cards: 'Cartões',
            extraWrapping: 'Embalagem extra',
            kitXWrappings: 'Kit {0} embalagens',
            emptyBagDoesNotStand: 'Saco vazio não para em pé!',
            possibleReasonsForEmptyBag: 'Possíveis motivos para a sua sacola estar vazia:',
            possibleReasonsForEmptyBagList: [
                'Você mudou de computador ou smartphone e não estava logado ao criar sua sacola.',
                'Os itens selecionados permaneceram em sua sacola por mais tempo do que o permitido pelo sistema. Este período pode variar de acordo com cada item selecionado.',
            ],
            youHaveDiscountCoupon: 'Você possui um cupom de desconto!',
            discountXWillBeAppliedValueYHtml:
                'O cupom <strong>{0}</strong> será automaticamente aplicado assim que você adicionar produtos à sua compra, dando um desconto de <strong>{1}</strong>.',
            youMayAlsoLike: 'Aproveite e leve também:',
            toAddXItems: 'Adicionar {n} item | Adicionar {n} itens',
            toRemoveXItems: 'Retirar {n} item | Retirar {n} itens',
            toRemoveItem: 'Remover produto',
        },
    },
    en: {
        _cart: {
            unableToLoadDataLogin:
                'Unable to load data. If already have an account, log in to find your purchase.',
            unableToLoadPurchase: 'We were unable to load your purchase data.',
            ideasToChangeStatus: 'Some ideas for you to change this situation:',
            addWrappingHere: 'Include gift wrapping by clicking here',
            addWrappingCardsHere: 'Include wrapping and gift cards by clicking here',
            collectingLoyaltyPointsX:
                'You are accumulating {n} point on the loyalty card! When you accumulate 10 points, you earn R$ 50 in purchases. | You are accumulating {n} points on the loyalty card! When you accumulate 10 points, you earn R$ 50 in purchases.',
            summary: 'Summary',
            appliedDiscounts: 'Applied discounts',
            onlyOrdersAboveX: 'Only orders above {n}',
            enterCustomName: 'Enter the custom name',
            giftOptions: 'Gift options',
            selectGiftCardsAndAdd:
                'Select the desired cards and add them with the button at the end:',
            selectGiftCardsWrappingsAndAdd:
                'Select the desired cards and wrappings and add them with the button at the end:',
            paperWrappingWithDimensionsX: 'Paper wrapping measuring {0} x {1} cm.',
            canvasWrappingWithDimensionsX: 'Canvas wrapping measuring {0} x {1} cm.',
            onlyXWrappingPerProduct:
                'Only {n} wrapping per product is allowed. | Only {n} wrapping per product is allowed.',
            card: 'Card',
            cards: 'Cards',
            extraWrapping: 'Extra wrapping',
            kitXWrappings: 'Kit {0} wrappings',
            emptyBagDoesNotStand: 'Empty bag does not stand!',
            possibleReasonsForEmptyBag: 'Possible reasons why your bag is empty:',
            possibleReasonsForEmptyBagList: [
                'You changed your computer or smartphone and were not logged in when creating your cart.',
                'The selected items remained in your cart for longer than the system allows. This period may vary according to each item selected.',
            ],
            youHaveDiscountCoupon: 'You have a discount coupon!',
            discountXWillBeAppliedValueYHtml:
                'The coupon <strong> {0} </strong> will automatically be applied as soon as you add products to your purchase, giving a discount of <strong> {1} </strong>.',
            youMayAlsoLike: 'You may also like',
            toAddXItems: 'Add {n} item | Add {n} items',
            toRemoveXItems: 'Remove {n} item | Remove {n} itens',
            toRemoveItem: 'Remove product',
        },
    },
});
