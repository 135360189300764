export const getDeepProp = (obj, path) => {
    if (path.indexOf('.') > 0) {
        const propertyChain = path.split('.')

        let propertyValue = { ...obj }
        while (propertyValue && propertyChain.length > 0) {
            propertyValue = propertyValue[propertyChain.shift()]
        }

        return propertyValue
    } else {
        return obj[path]
    }
}
