<template>
    <button class="btn" :class="classes" :disabled="loading || disabled" @click="$emit('click')">
        <span v-if="loading" class="btn-spinner-wrapper">
            <span class="cr-spinner-small"></span>
        </span>
        <slot />
    </button>
</template>

<script>
    export default {
        name: 'CrButton',

        props: {
            color: {
                type: String,
                validator: function (value) {
                    return (
                        [
                            'default',
                            'primary',
                            'info',
                            'success',
                            'warning',
                            'danger',
                            'light', // TODO change name?
                        ].indexOf(value) !== -1
                    );
                },
            },
            size: {
                type: String,
                validator(value) {
                    return ['xs', 'md', 'lg', 'xl'].indexOf(value) !== -1;
                },
            },
            wide: Boolean,
            disabled: Boolean,
            loading: Boolean,
        },

        computed: {
            classes() {
                let classes = [];
                if (this.color) classes.push(`btn-${this.color}`);
                if (this.loading) classes.push('btn-loading loading');
                if (this.wide) classes.push('btn-wide');
                if (this.size) classes.push(`btn-${this.size}`);
                return classes.join(' ');
            },
        },
    };
    //Button
</script>
