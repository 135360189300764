import { appendQueryString } from './url';

const getScope = function (name) {
    return !window.initialScope || typeof window.initialScope[name] === 'undefined'
        ? null
        : window.initialScope[name];
};

export const getProductThumbLink = (
    fallback,
    thumb_png,
    fabric_id,
    append = {},
    preferFallback = false
) => {
    if (fabric_id && (!preferFallback || !fallback)) {
        const fabricBg = (getScope('FABRICS_BG') || [])[fabric_id];

        if (thumb_png && fabricBg && fabricBg[0] === '#') {
            return appendQueryString(thumb_png, {
                bg: fabricBg.replace('#', ''),
                ...append,
            });
        } else if (thumb_png && fabricBg) {
            return appendQueryString(`${getScope('CDN_SHARED')}/${fabricBg}`, {
                'mark-alpha': 90,
                'mark-scale': '80',
                'mark-align': 'middle,center',
                mark: thumb_png,
                ...append,
            });
        }
    }

    if (fallback) {
        return appendQueryString(fallback, append);
    }

    return null;
};
