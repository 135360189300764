export default () => ({
    pt: {
        _shop: {
            toBuy: 'Comprar',
            addToCart: 'Adicionar à sacola',
            priceFrom: 'A partir de',
            priceTo: 'Por',
            _price: {
                from: 'A partir de',
                to: 'Por',
                fromToHtml: 'De <del>{0}</del> por {1}',
                timesInterestFreeX: '{0}x {1} sem juros',
                upToXTimesInterestFree: 'Até {0}x {1} sem juros',
            },
            _advantages: {
                freeShipping: 'Leve agora com frete grátis!',
                freeShippingAnyPurchase: 'Frete grátis em qualquer compra. Aproveite!',
                freeShippingAbove: 'Frete grátis a partir de {0}',
                freeFirstExchange: 'Sua 1ª troca no site é grátis',
            },
            _wishlist: {
                add: 'Adicionar à wishlist',
                successfullyAddedHtml:
                    'Produto adicionado com sucesso.<strong><a href="{link}">Clique aqui</a></strong> para ver os produtos da sua wishlist.',
                stockWarning:
                    'Assim que o produto estiver disponível novamente em estoque te avisaremos por email! :)',
                selectSize: 'Selecione o tamanho que deseja adicionar em sua wishlist:',
            },
            yourBag: 'Sua sacola',
            toViewBag: 'Ver sacola',
            discounts: 'Descontos',
            total: 'Total',
            youHaveNotAddedItems: 'Você ainda não adicionou itens à sua sacola',
            toFinishOrder: 'Fechar pedido',
            productsInYourBagX: '+{n} produto em sua sacola | +{n} produtos em sua sacola',
            subtotal: 'Subtotal',
            items: 'Nenhum item | {n} item | {n} itens',
            myBag: 'Minah sacola',
            minimumValueNotReached: 'Pedido mínimo não atingido',
            _prime: {
                getForFreeShipping:
                    'Adquira o Chico Rei Prime para desfrutar de frete grátis por 1 ano em todos os pedidos, sem valor mínimo.',
                gotFreeShipping:
                    'Pronto, você está prestes a adquirir frete grátis ilimitado por 1 ano!',
                dontWantSign: 'Não quero assinar',
            },
            expressProduction: 'Produção Expressa',
            onDemandProduction: 'Produção Sob Demanda',
            withExpressProduction: 'Com Produção Expressa',
            _discount: {
                enter: 'Digite o código do cupom / vale-presente',
                notCumulativePromos: 'Desconto não cumulativo com outros cupons ou promoções',
                code: 'Código do cupom',
            },
            _stock: {
                soldOut: 'Esgotado',
                remainX: 'Resta {n} | Restam {n}',
                inStock: 'Produto em estoque',
                lastUnit: 'Última unidade!',
                qtyUnavailable: 'Quantidade não disponível',
            },
            itemsOnDemandX: '{n} item sob demanda | {n} itens sob demanda',
            selectWhatAddPurchase: 'Selecione o que deseja adicionar à sua sacola:',
            interestFree: 'sem juros',
            installmentsX: '{0} parcela | {0} parcelas',
            inCash: 'à vista',
            creditCard: 'Cartão de Crédito',
            bankBillet: 'Boleto Bancário',
            bankDeposit: 'Transferência Bancária',
            paymentLink: 'Link de Pagamento',
            closeBag: 'Fechar sacola',
        },
    },
    en: {
        _shop: {
            toBuy: 'Buy',
            addToCart: 'Add to cart',
            _price: {
                from: 'From {0}',
                to: 'For',
                fromToHtml: 'From <del>{0}</del> for {1}',
                timesInterestFreeX: '{0}x {1} interest-free',
                upToXTimesInterestFree: 'Up to {0}x {1} interest-free',
            },
            _advantages: {
                freeShipping: 'Buy now with free shipping!',
                freeShippingAnyPurchase: 'Free shipping in any purchase. Enjoy!',
                freeShippingAbove: 'Free shipping in orders above {0}',
                freeFirstExchange: 'Your first exchange is free',
            },
            _wishlist: {
                add: 'Add to Wishlist',
                successfullyAddedHtml:
                    'Product successfully added. <strong> <a href="{link}"> Click here </a> </strong> to see the products on your wishlist.',
                stockWarning:
                    'As soon as the product is available again in stock we will notify you by email! :)',
                selectSize: 'Select the size you want to add to your wishlist:',
            },
            errorAddProductCart: 'Não foi possível adicionar este produto à sua sacola. ',
            yourBag: 'Your bag',
            toViewBag: 'See bag',
            discounts: 'Discounts',
            total: 'Total',
            youHaveNotAddedItems: 'You have not yet added items to your purchase',
            toFinishOrder: 'Finish order',
            productsInYourBagX: '+{n} product in your bag | {n} products in your bag',
            subtotal: 'Subtotal',
            items: 'No items | {n} item | {n} items',
            myPurchase: 'My purchase',
            minimumValueNotReached: 'Minimum value not met',
            _prime: {
                getForFreeShipping:
                    'Get Chico Rei Prime to enjoy free shipping for 1 year on all orders, with no minimum value.',
                gotFreeShipping: "You're about to purchase unlimited free shipping for 1 year!",
                dontWantSign: "I don't want to sign",
            },
            expressProduction: 'Express Production',
            onDemandProduction: 'On Demand Production',
            _discount: {
                enter: 'Enter the discount coupon',
                notCumulativePromos: 'Discount not cumulative with other coupons or promotions',
                code: 'Discount coupon code',
            },
            _stock: {
                soldOut: 'Sold out',
                remainX: '{n} remaining',
                inStock: 'In stock',
                lastUnit: 'Last item! | Last {n} items!',
                qtyUnavailable: 'Quantity not available',
            },
            itemsOnDemandX: '{n} item on demand | {n} items on demand',
            selectWhatAddPurchase: 'Select what you want to add to your purchase:',
            interestFree: 'interest free',
            installmentsX: '{0} installment | {0} installments',
            inCash: 'in cash',
            creditCard: 'Credit Card',
            bankBillet: 'Billet',
            bankDeposit: 'Bank Deposit',
            paymentLink: 'Payment Link',
            closeBag: 'Close bag',
        },
    },
});
