import { cartService } from '../../../../service/resource';
import { setCookie } from '../../../common';
import { CHECKOUT_STEPS } from '../consts';
import axios from 'axios';

const CancelToken = axios.CancelToken;

const addressInitialState = {
    requests: 0,
    error: null,
    cancelToken: null,
};

export default (store) => ({
    state: addressInitialState,

    cancelRequest() {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel();
            this.state.cancelToken = undefined;
        }
    },

    select(address) {
        return new Promise((resolve, reject) => {
            if (store.state.step.active !== CHECKOUT_STEPS.STEP_ADDRESS) {
                reject();
            }

            this.state.error = null;
            this.state.requests++;

            this.cancelRequest();
            this.state.cancelToken = CancelToken.source();

            cartService
                .update(
                    store.state.cart.cart.id,
                    {
                        cotacaoId: 0,
                        cotacaoResponseId: 0,
                        addressDeliveryId: address.address_id,
                        promotionalCard: 0,
                    },
                    {
                        cancelToken: this.state.cancelToken.token,
                    }
                )
                .then((response) => {
                    if (
                        store.state.cart.cart.total_products !== response.data.data.total_products
                    ) {
                        this.state.requests--;
                        store.cart.fetch().then(() => {
                            store.step.next();
                            resolve();
                        });
                    } else {
                        store.state.cart.cart.address = store.state.cart.cart.address || {};
                        store.state.cart.cart.address.data = address;

                        store.cart.updateStateWithResponse(response.data.data);

                        setCookie('cep', address.zip_code);
                        setCookie('country_id', address.country_id);

                        this.state.requests--;
                        store.step.next();
                        resolve();
                    }
                })
                .catch(() => {
                    this.state.error = {
                        msg: '_checkout._address.errorSavingAddress',
                        i18n: true,
                    };
                    this.state.requests--;
                    reject();
                });
        });
    },
});
