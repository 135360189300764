import { createStore } from '../helpers';
import cart from './modules/cart';
import discount from './modules/discount';
import step from './modules/step';
import address from './modules/address';
import carrier from './modules/carrier';
import payment from './modules/payment';

const store = createStore({
    modules: {
        address,
        carrier,
        cart,
        discount,
        payment,
        step,
    },

    isBusy() {
        return (
            this.state.address.requests ||
            this.state.cart.requests ||
            this.state.carrier.requests ||
            this.state.discount.requests ||
            this.state.payment.requests ||
            this.state.step.requests ||
            this.state.cart.checkedOut
        );
    },

    redirect() {
        window.location.href = initialScope.URL_STORE_PREFIX + '/sacola';
    },

    allowCheckout() {
        return (
            store.step.isStepAddressValid() &&
            store.step.isStepCarrierValid() &&
            store.step.isStepPaymentValid()
        );
    },
});

export default store;
