import { fabric } from 'fabric';

const helperCanvas = document.createElement('canvas');
helperCanvas.width = 1800;
helperCanvas.height = 2700;

const addImageToCanvasFromUrl = async function (canvas, imageUrl, props = {}) {
    return new Promise((resolve, reject) => {
        fabric.Image.fromURL(
            imageUrl,
            function (imageObject, isError) {
                if (isError) {
                    return reject(isError);
                }

                if (props.scale) {
                    imageObject.scale(props.scale);
                }

                imageObject.set({
                    left: (props.left || 0) - (props.offsetLeft || 0),
                    top: (props.top || 0) - (props.offsetTop || 0),
                    opacity: props.opacity || 1,
                });

                if (props.clipWidth || props.clipHeight) {
                    imageObject.clipPath = new fabric.Rect({
                        width: props.clipWidth || imageObject.height,
                        height: props.clipHeight || imageObject.height,
                        left: props.left || 0,
                        top: props.top || 0,
                        absolutePositioned: true,
                    });
                }

                canvas.add(imageObject);
                resolve();
            },
            { crossOrigin: 'Anonymous' }
        );
    });
};

const addWrappedImageToCanvasFromUrl = async function (canvas, imageUrl, props = {}) {
    return new Promise((resolve, reject) => {
        const artImage = new Image();
        artImage.crossOrigin = 'Anonymous';
        artImage.onload = async () => {
            const ctx = helperCanvas.getContext('2d');
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.clearRect(0, 0, helperCanvas.width, helperCanvas.height);

            const step = 1 / Math.max(artImage.width, 400);
            for (let i = 0; i < 1; i += step) {
                let a = i * props.wrap.piCircle;
                let a1 = (i + step * 2) * props.wrap.piCircle;
                let ix = i * artImage.width * 1.2;
                let iw = step * artImage.width * 1.2;
                a += props.wrap.angle * props.wrap.piCircle * 2;
                a1 += props.wrap.angle * props.wrap.piCircle * 2;
                a = props.wrap.piCircle - a;
                a1 = props.wrap.piCircle - a1;
                let x = helperCanvas.width * 0.5;
                let y = helperCanvas.height * 0.1;

                let x1 = x + Math.cos(a1) * props.wrap.widthFactor;
                let y1 = y + Math.sin(a) * props.wrap.tilt;
                x += Math.cos(a) * props.wrap.widthFactor;
                y += Math.sin(a) * props.wrap.tilt;
                let s = Math.sin(a);
                let s1 = Math.sin(a1);
                if (s > 0 || s1 > 0) {
                    ctx.drawImage(
                        artImage,
                        ix,
                        0,
                        iw,
                        artImage.height,
                        x1 + props.wrap.left,
                        props.wrap.top + (y - s * props.wrap.perspective * 0.5),
                        x - x1,
                        props.wrap.height + s * props.wrap.perspective
                    );
                }
            }

            await addImageToCanvasFromUrl(canvas, helperCanvas.toDataURL());
            resolve();
        };

        artImage.onerror = () => {
            reject();
        };

        artImage.src = imageUrl;
    });
};

const addReplicas = async function (canvas, image, replicas) {
    for (let i = 0; i < replicas.length; i += 1) {
        await addImageToCanvasFromUrl(canvas, image, replicas[i]);
    }
};

export const generate = async function (artPng, baseImages, cdn) {
    const results = {};

    for (const baseImage of baseImages) {
        const canvas = new fabric.StaticCanvas(null, {
            width: 1800,
            height: 2700,
            backgroundColor: 'rgb(255,255,255)',
        });

        const baseImagePath = cdn + '/' + baseImage.url;

        if (baseImage.params.generation.baseLast) {
            if (baseImage.params.generation.replicate) {
                await addReplicas(canvas, artPng, baseImage.params.generation.replicate);
            }

            if (baseImage.params.generation.wrap) {
                await addWrappedImageToCanvasFromUrl(canvas, artPng, baseImage.params.generation);
            } else {
                await addImageToCanvasFromUrl(canvas, artPng, baseImage.params.generation);
            }
            await addImageToCanvasFromUrl(canvas, baseImagePath);
        } else {
            await addImageToCanvasFromUrl(canvas, baseImagePath);

            if (baseImage.params.generation.replicate) {
                await addReplicas(canvas, artPng, baseImage.params.generation.replicate);
            }

            if (baseImage.params.generation.wrap) {
                await addWrappedImageToCanvasFromUrl(canvas, artPng, baseImage.params.generation);
            } else {
                await addImageToCanvasFromUrl(canvas, artPng, baseImage.params.generation);
            }
        }

        if (baseImage.params.generation.mask) {
            await addImageToCanvasFromUrl(canvas, baseImage.params.generation.mask.url);
        }

        canvas.renderAll();

        results[baseImage.id] = canvas.toDataURL({ format: 'jpeg' });

        canvas.dispose();
    }

    return results;
};
