export const appendQueryString = (url, queries = {}) => {
    if (!url) {
        return null;
    }

    url = new URL(url);

    for (let query in queries) {
        if (!queries.hasOwnProperty(query)) continue;

        const queryValue = queries[query];
        if (queryValue !== null && queryValue !== undefined) {
            url.searchParams.set(query, queryValue);
        }
    }

    return url.toString();
};
