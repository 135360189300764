<template>
    <div id="header-cart-drawer" class="text-yanone hidden-not-touch">
        <div id="cart-fast-content-overlay" :class="{ show: visible }"></div>
        <cart-fast-content
            id="cart-fast-content-mobile"
            :class="{ open: visible }"
            :loading="loading"
            :cart="cart"
            :count="cartProductsCount"
        >
        </cart-fast-content>
    </div>
</template>

<script>
    import CartFastContent from '../CartFastContent';
    import HeaderCart from '../HeaderCart';

    export default {
        name: 'HeaderCartDrawer',
        extends: HeaderCart,
        components: {
            'cart-fast-content': CartFastContent,
        },
        props: {
            customer: Object,
        },
    };
</script>
