<template>
    <div class="form-group">
        <input
            :id="innerId"
            :checked="isChecked"
            :disabled="disabled"
            :name="name"
            class="cr-checkbox"
            type="checkbox"
            @change="changed($event)"
        />
        <label :for="innerId">
            <span v-if="label">
                {{ label }}
            </span>
            <span v-else>
                <wbr />
            </span>
        </label>
    </div>
</template>

<script>
    export default {
        compatConfig: { COMPONENT_V_MODEL: false },

        name: 'Checkbox',

        model: {
            prop: 'modelValue',
            event: 'update:modelValue',
        },

        props: {
            modelValue: {
                default: false,
            },
            value: {
                default: '',
            },
            id: String,
            label: String,
            name: String,
            trueValue: {
                default: true,
            },
            falseValue: {
                default: false,
            },
            disabled: Boolean,
        },

        data() {
            let innerId;

            if (this.id) {
                innerId = this.id;
            } else
                do {
                    innerId = `selectable-${Math.floor(Math.random() * 1000000)}`;
                } while (document.getElementById(innerId));

            return { innerId };
        },

        computed: {
            isChecked() {
                if (this.modelValue instanceof Array) {
                    return this.modelValue.indexOf(this.value) !== -1;
                } else {
                    return this.modelValue === this.trueValue;
                }
            },
        },

        methods: {
            changed(event) {
                if (this.modelValue instanceof Array) {
                    const newArray = [...this.modelValue];

                    if (event.target.checked) {
                        newArray.push(this.value);
                    } else {
                        newArray.splice(newArray.indexOf(this.value), 1);
                    }

                    this.$emit('change', newArray); // Vue 2
                    this.$emit('update:modelValue', newArray);
                } else {
                    const value = event.target.checked ? this.trueValue : this.falseValue;
                    this.$emit('change', value); // Vue 2
                    this.$emit('update:modelValue', value);
                }
            },
        },
    };
</script>
