<script>
export default {
    name: 'RequestsEmitter',

    data() {
        return { exposedRequests: 0 }
    },

    beforeDestroy() {
        if (this.exposedRequests > 0) {
            this.$emit('done')
        }
    },

    watch: {
        exposedRequests(to, from) {
            if (to === 0) this.$emit('done')
            else if (to > 0 && from === 0) this.$emit('busy')
        },
    },
}
</script>
