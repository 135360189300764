<template>
    <form autocomplete="off">
        <Checkbox id="checkbox-pix-key" v-model="pixSelected" class="mb" label="Usar chave pix" />
        <TextField
            label="Nome completo"
            v-model="fullName"
            :error.sync="errors.fullName"
            @blur="validateField('fullName')"
        />
        <TextField
            v-if="pixSelected"
            v-model="pixKey"
            :error.sync="errors.pixKey"
            label="Chave Pix"
            @blur="validateField('pixKey')"
        />

        <div v-if="!pixSelected">
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <CleaveField
                        label="CPF"
                        v-model="cpf"
                        :raw="false"
                        :options="cleaveCpf"
                        :error.sync="errors.cpf"
                        @blur="validateField('cpf')"
                    />
                </div>

                <div class="col-xs-12 col-md-6">
                    <TextField
                        label="Banco"
                        v-model="bank"
                        :error.sync="errors.bank"
                        @blur="validateField('bank')"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <TextField
                        label="Agência"
                        :min="0"
                        v-model="branch"
                        :error.sync="errors.branch"
                        @blur="validateField('branch')"
                    />
                </div>
                <div class="col-xs-12 col-md-6">
                    <TextField
                        label="Conta"
                        :min="0"
                        v-model="account"
                        :error.sync="errors.account"
                        @blur="validateField('account')"
                    />
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import CleaveField from '../../input/cleave-field/CleaveField';
    import TextField from '../../input/text-field/TextField';
    import Checkbox from '../../input/checkbox/Checkbox';
    import { cleaveCpf } from '../../../script/cleave';

    export default {
        name: 'BankTransferForm',

        components: { CleaveField, TextField, Checkbox },

        data() {
            return {
                fullName: '',
                bank: '',
                branch: '',
                account: '',
                cpf: '',
                pixKey: '',
                pixSelected: false,

                errors: {
                    fullName: null,
                    bank: null,
                    branch: null,
                    account: null,
                    cpf: null,
                },
                cleaveCpf,
            };
        },

        computed: {
            isValid() {
                return this.pixSelected
                    ? this.fullName.toString().trim().length > 0 &&
                          this.pixKey.toString().trim().length > 0
                    : this.fullName.toString().trim().length > 0 &&
                          this.bank.toString().trim().length > 0 &&
                          this.branch.toString().trim().length > 0 &&
                          this.account.toString().trim().length > 0 &&
                          this.cpf.toString().trim().length > 0;
            },

            text() {
                return this.pixSelected
                    ? `${this.fullName}\nChave Pix:${this.pixKey}`
                    : `${this.fullName}\n${this.cpf}\n\nBanco: ${this.bank}\nAgência: ${this.branch}\nConta: ${this.account}`;
            },
        },

        watch: {
            text(to) {
                this.$emit('input', this.isValid ? to : null);
            },
        },

        methods: {
            validateField(name) {
                this.errors[name] = !this[name].toString().trim().length;
            },
        },
    };
</script>
