<template>
    <FieldWrapper :id="innerId" :error="error" :hint="hint" :label="label">
        <textarea
            :id="innerId"
            ref="inputFieldRef"
            :autocomplete="autocomplete"
            :class="getInputClasses"
            :cols="cols"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :readonly="readonly"
            :rows="rows"
            :value="modelValue"
            @blur="$emit('blur', $event)"
            @change="$emit('change', $event)"
            @input="onInput"
        >
        </textarea>
    </FieldWrapper>
</template>

<script>
    import Field from '../field/Field';
    import FieldWrapper from '../field-wrapper/FieldWrapper';

    export default {
        compatConfig: { COMPONENT_V_MODEL: false },

        name: 'TextAreaField',

        extends: Field,

        components: { FieldWrapper },

        props: {
            cols: [String, Number],
            rows: [String, Number],
        },

        methods: {
            onInput(event) {
                if (this.error && this.autoCleanErrors) {
                    this.$emit('update:error', null);
                }

                this.$emit('update:modelValue', event.target.value);
            },
        },
    };
</script>
