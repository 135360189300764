<template>
    <FieldWrapper
        :id="innerId"
        :tooltip="tooltip"
        :add-on-after="addOnAfter"
        :add-on-before="addOnBefore"
        :error="error"
        :hint="hint"
        :label="label"
    >
        <input
            :id="innerId"
            ref="inputFieldRef"
            :autocomplete="autocomplete"
            :class="getInputClasses"
            :disabled="disabled"
            :maxlength="maxlength"
            :name="name"
            :placeholder="placeholder"
            :readonly="readonly"
            :type="type"
            :value="modelValue"
            @input="onInput"
            @blur="$emit('blur')"
            @keyup.up="$emit('up')"
            @keyup.down="$emit('down')"
            @keyup.enter="$emit('enter')"
            @keyup.esc="$emit('esc')"
        />
        <div
            v-show="showClearIcon && !disabled && modelValue && modelValue.length > 0"
            class="input-clear"
            @click="onClearIconClick"
        />
    </FieldWrapper>
</template>

<script>
    import FieldWrapper from '../field-wrapper/FieldWrapper';
    import Field from '../field/Field';

    export default {
        compatConfig: { COMPONENT_V_MODEL: false },

        name: 'TextField',

        extends: Field,

        components: { FieldWrapper },

        props: {
            type: {
                type: String,
                default: 'text',
            },
            showClearIcon: Boolean,
        },

        emits: ['update:error', 'blur', 'up', 'down', 'enter', 'esc'],

        methods: {
            onInput(event) {
                if (this.error && this.autoCleanErrors) {
                    this.$emit('update:error', null);
                }

                this.$emit('update:modelValue', event.target.value);
            },

            onClearIconClick() {
                this.$emit('update:modelValue', '');
                this.$refs.inputFieldRef.focus();
            },
        },
    };
</script>
