export default () => ({
    pt: {
        _gallery: {
            toSend: 'Enviar foto',
            sendAndGetDiscount: 'Envie e ganhe um desconto maroto',
            sendAndGetDiscountLong: 'Envie sua foto usando esse produto e ganhe um desconto maroto',
            loading: '@:loading @.lower:(gallery)...',
            wellInPhoto: 'Bem na foto :)',
            wearingProductInPhoto: 'Com qual produto você está vestido(a) na foto?',
            sendWearingProductHtml: 'Envie uma foto vestindo <strong>"{0}"</strong>.',
            selectTags: 'Clique nas hashtags que você deseja adicionar à sua foto:',
            maxSizeX: 'Tamanho máximo',
            minDimensions: 'Dimensões mínimas',
            allowedFormat: 'Formato permitido',
            agreement:
                'Ao enviar a foto você permite a exibição pública de seu nome, cidade, estado, data de cadastro, comentários e fotos enviadas para a Galeria Uma Penca.',
            awareAndAgreed: 'Estou ciente e concordo',
            waitSubmit: 'Aguarde enquanto processamos sua foto...',
            photoSubmitted:
                'Obrigado, sua foto foi enviada com sucesso! Seu desconto será liberado após a aprovação pela nossa equipe ;)',
            readTermsHtml:
                '<span class="{linkClass}">Clique aqui</span>&nbsp;para conferir o nosso regulamento sobre o envio de fotos.',
            attachedPhoto: 'Foto anexada',
            attachPhotoHere: 'Anexe aqui a sua foto',
            sendYourPhotoToo: 'Envie sua foto também',
            sendYourPhotoToUs: 'Envie sua foto pra gente',
            sendYourFeedback: 'Mande seu comentário',
            sampleProducts: 'Ex: dragão, Elza...',
            sampleCities: 'Ex: Juiz de Fora, São Paulo...',
            sampleHashtags: 'Ex: selfie, geek...',
            mustBeImage: 'Arquivo deve ser uma imagem',
            maxAllowedSizeX: 'Tamanho máximo permitido é {0}MB"',
            searchHereByName: 'Pesquise aqui pelo nome',
        },
    },
    en: {
        _gallery: {
            toSend: 'Send photo',
            sendAndGetDiscount: 'Submit and get a nice discount',
            sendAndGetDiscountLong: 'Submit your photo using this product and get a nice discount',
            loading: '@:loading @.lower:(gallery)...',
            wellInPhoto: 'Well in the picture :)',
            wearingProductInPhoto: 'Which product are you wearing in the photo?',
            sendWearingProductHtml: 'Upload a photo wearing <strong> "{0}" </strong>.',
            selectTags: 'Click on the hashtags you want to add to your photo:',
            maxSizeX: 'Max size',
            minDimensions: 'Minimum dimenions',
            allowedFormat: 'Allowed format',
            agreement:
                'By sending the photo you allow the public display of your name, city, state, registration date, comments and photos sent to Uma Penca Gallery.',
            awareAndAgreed: 'I am aware and agree',
            waitSubmit: 'Please wait while we process your photo...',
            photoSubmitted:
                'Thank you, your photo has been successfully sent! Your discount will be released after approval by our team ;)',
            askReview: 'Deseja aproveitar para nos dizer o que achou sobre o produto "{0}"?',
            readTermsHtml:
                '<span class="{linkClass}">Click here</span> to check our regulation on sending photos.',
            attachedPhoto: 'Attached photo',
            attachPhotoHere: 'Attach photo here',
            sendYourPhotoToo: 'Send your photo too',
            sendYourPhotoToUs: 'Send your photo to us',
            sendYourFeedback: 'Send your comment',
            sampleProducts: 'Ex: dragon, Elza...',
            sampleCities: 'Ex: São Paulo, New York...',
            sampleHashtags: 'Ex: selfie, geek...',
            mustBeImage: 'File must be an image',
            maxAllowedSizeX: 'Maximum allowed size is {0}MB "',
            searchHereByName: 'Search here by name',
        },
    },
});
