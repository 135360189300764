export default () => ({
    pt: {
        _catalog: {
            _error: {
                unableToLoad:
                    'Não foi possível carregar os produtos. Verifique sua conexão e atualize a página para tentar novamente.',
                noResults: 'Não encontramos resultados para a busca ou filtro utilizado.',
            },
            _filter: {
                resultsX: 'Nenhum resultado | {n} resultado | {n} resultados',
                gender: 'Gênero',
                withMultipleColors: 'Com várias cores',
                withDeliveryReady: 'Com pronta entrega',
                selectSize: 'Selecione um tamanho',
                toClear: 'Limpar filtros',
            },
            _sort: {
                sortedBy: 'Ordenados por',
                default: 'Padrão',
                lowestPrice: 'Menor preço',
                highestPrice: 'Maior preço',
                releases: 'Lançamentos',
            },
        },
    },
    en: {
        _catalog: {
            _error: {
                unableToLoad:
                    'The products could not be loaded. Check your connection and refresh the page to try again. ',
                noResults: "We couldn't find any results for the search or filter used.",
            },
            _filter: {
                resultsX: 'No results | {n} result | {n} results',
                gender: 'Gender',
                withMultipleColors: 'With multiple colors',
                withDeliveryReady: 'With prompt delivery',
                selectSize: 'Select a size',
                toClear: 'Clear filters',
            },
            _sort: {
                sortedBy: 'Sorted by',
                default: 'Default',
                lowestPrice: 'Lowest price',
                highestPrice: 'Highest price',
                releases: 'Releases',
            },
        },
    },
});
