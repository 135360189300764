<template>
    <div class="pos-relative">
        <nav>
            <ul
                v-if="pagination && pagination.total_pages && pagination.total_pages > 1"
                class="cr-pagination"
            >
                <li
                    v-if="pagination.current_page === 1"
                    class="cr-pagination-previous cr-pagination-disabled"
                >
                    <a aria-label="Anterior">Anterior</a>
                </li>
                <li
                    v-else
                    class="cr-pagination-previous"
                    @click.prevent="changePage(pagination.current_page - 1)"
                >
                    <a aria-label="Anterior" rel="prev">Anterior</a>
                </li>

                <li class="hidden-xs-up cr-pagination-input">
                    <input
                        :value="pagination.current_page"
                        type="number"
                        v-on:keyup.enter="changePage($event.target.value)"
                    />
                    <span class="cr-pagination-total">/ {{ pagination.total_pages }}</span>
                </li>

                <template v-for="link in links" class>
                    <li
                        :key="`${link}-current`"
                        v-if="link === pagination.current_page"
                        class="cr-pagination-current hidden-xs-down"
                    >
                        <a>{{ link }}</a>
                    </li>
                    <li
                        v-else-if="link === '...'"
                        :key="`${link}-skip`"
                        class="cr-pagination-skip hidden-xs-down"
                    ></li>
                    <li
                        v-else
                        class="hidden-xs-down"
                        :key="`${link}-another-page`"
                        @click.prevent="changePage(link)"
                    >
                        <a>{{ link }}</a>
                    </li>
                </template>

                <li
                    v-if="pagination.current_page < pagination.total_pages"
                    class="cr-pagination-next"
                    @click.prevent="changePage(pagination.current_page + 1)"
                >
                    <a aria-label="Próxima" rel="next">Próxima</a>
                </li>
                <li v-else class="cr-pagination-next cr-pagination-disabled">
                    <a aria-label="Próxima">Próxima</a>
                </li>
            </ul>
        </nav>

        <div
            v-if="
                allowPerPageChange && (pagination.hits.length >= 36 || pagination.current_page > 1)
            "
            class="pos-absolute pos-right pos-center-y hidden-lg-down"
        >
            <div class="d-inline-flex flex-align-center">
                <label class="text-yanone mr-2"> Produtos por página: </label>
                <SelectField
                    :value="pagination.per_page"
                    input-classes="input-sm"
                    @change="changePerPage"
                >
                    <option>36</option>
                    <option>60</option>
                    <option>120</option>
                </SelectField>
            </div>
        </div>
    </div>
</template>

<script>
    import SelectField from '../../input/select-field/SelectField';

    export default {
        name: 'Paginator',

        components: { SelectField },

        props: {
            pagination: {
                required: true,
            },

            allowPerPageChange: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            links() {
                let pages = [1];
                let middle = [];

                for (
                    let i =
                        this.pagination.current_page - 4 < 2 ? 2 : this.pagination.current_page - 3;
                    i <
                    (this.pagination.current_page + 4 > this.pagination.total_pages
                        ? this.pagination.total_pages
                        : this.pagination.current_page + 4);
                    i++
                ) {
                    middle.push(i);
                }

                if (middle.length > 0) {
                    if (middle[0] - 1 > 1) middle[0] = '...';

                    if (middle[middle.length - 1] + 1 < this.pagination.total_pages)
                        middle[middle.length - 1] = '...';

                    middle.forEach((val) => {
                        pages.push(val);
                    });
                }

                pages.push(this.pagination.total_pages);

                return pages;
            },
        },

        methods: {
            changePage(page) {
                this.$emit('change-page', {
                    perPage: this.pagination.per_page,
                    page: page,
                });
            },

            changePerPage(perPage) {
                this.$emit('change-page', { perPage: perPage, page: 1 });
            },
        },
    };
</script>
