export const cleavePhone = {
    phone: true,
    phoneRegionCode: 'BR',
};

export const cleaveCep = {
    delimiter: '-',
    blocks: [5, 3],
    numericOnly: true,
};

export const cleaveCpf = {
    delimiters: ['.', '.', '-'],
    blocks: [3, 3, 3, 2],
    numericOnly: true,
};

export const cleaveCnpj = {
    delimiters: ['.', '.', '/', '-'],
    blocks: [2, 3, 3, 4, 2],
    numericOnly: true,
};

export const cleaveDate = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y'],
};
