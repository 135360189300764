<template>
    <div
        id="header-cart-desktop"
        class="header-cart cr-popover-right-bottom-sm-up text-yanone"
        :class="{ 'cr-popover-show': visible }"
        @click.stop="show"
        @mouseenter="show"
    >
        <a class="btn btn-image btn-light btn-header btn-height" @mouseleave="onMouseLeave">
            <div class="header-cart-icon-wrapper pos-relative">
                <a
                    :href="cartLink() ? $scope.URL_STORE_PREFIX + '/sacola' : ''"
                    :style="cartLink() ? null : 'pointer-events: none;'"
                >
                    <div class="header-cart-icon icon-cr icon-wallet mt-3"></div>
                </a>
                <!-- "display: none" esconde antes de renderizar no vue -->
                <span
                    class="header-cart-counter pos-absolute pos-right pos-top"
                    style="display: none; transform: translate(40%, 35%)"
                    v-show="cartProductsCount > 0"
                    :class="{ 'count-cart-max': cartProductsCount > 9 }"
                >
                    {{ cartProductsCount }}
                </span>
            </div>
        </a>

        <div id="header-cart-popover" class="cr-popover-content" @mouseleave="onMouseLeave">
            <cart-fast-content
                :loading="loading"
                :cart="cart"
                :count="cartProductsCount"
                :iscartpath="cartLink()"
                :show-all-products="false"
            >
            </cart-fast-content>
        </div>
    </div>
</template>

<script>
    import HeaderCart from '../HeaderCart';
    import { cartStore } from '../../../../common/store/cart-store';
    import CartFastContent from '../CartFastContent';

    export default {
        name: 'HeaderCartDesktop',
        extends: HeaderCart,

        components: {
            CartFastContent,
        },

        data() {
            return {
                mouseIsOver: false,
                url_pathname: window.location.pathname,
            };
        },

        watch: {
            mouseIsOver(newVal) {
                if (newVal) this.fetch();
            },
        },

        methods: {
            onMouseLeave() {
                this.mouseIsOver = false;
                cartStore.hide();
            },
            cartLink() {
                return this.url_pathname !== '/sacola' ? true : false;
            },
        },
    };
</script>
