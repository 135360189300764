<template>
    <FieldWrapper
        :id="innerId"
        :add-on-after="addOnAfter"
        :add-on-before="addOnBefore"
        :error="errorToShow"
        :hint="hint"
        :label="label"
        :tooltip="tooltip"
    >
        <div class="d-flex">
            <input
                :id="innerId"
                :disabled="disabled"
                :placeholder="(file || {}).name || 'Nenhum arquivo selecionado'"
                :class="getInputClasses"
                class="mr"
                readonly
            />
            <label :class="{ 'btn-disabled': disabled }" class="btn">
                Selecionar...
                <input
                    :accept="accept"
                    :disabled="disabled"
                    type="file"
                    @change="selectUpload($event)"
                />
            </label>
        </div>
    </FieldWrapper>
</template>

<script>
    import FieldWrapper from '../field-wrapper/FieldWrapper';
    import { fieldWrapperProps } from '../../../helpers/input';
    import Field from '../field/Field';

    export default {
        compatConfig: { COMPONENT_V_MODEL: false },

        name: 'FileField',

        components: { FieldWrapper },

        extends: Field,

        props: {
            ...fieldWrapperProps,
            maxFileSize: Number,
            maxFileErrorMsg: String,
            accept: String,
            acceptErrorMsg: String,
        },

        emits: ['select'],

        data() {
            return {
                innerError: null,
                file: null,
            };
        },

        watch: {
            file(to) {
                this.$emit('select', to);
            },
        },

        computed: {
            errorToShow() {
                return this.innerError || this.error;
            },

            getInputClasses() {
                let classes = this.inputClasses ? this.inputClasses.split(' ') : [];
                if (this.errorToShow) classes.push('input-danger');
                return classes;
            },

            acceptTypes() {
                return this.accept ? this.accept.split(',').map((s) => s.trim()) : null;
            },
        },

        methods: {
            selectUpload(event) {
                this.file = null;

                if (!(event && event.target && event.target.files[0])) return;

                const file = event.target.files[0];

                if (this.maxFileSize && file.size > this.maxFileSize) {
                    this.innerError = this.maxFileErrorMsg;
                } else if (this.accept && !this.validateType(file)) {
                    this.innerError = this.acceptErrorMsg;
                } else {
                    this.innerError = null;
                    this.file = file;
                }
            },

            validateType(file) {
                for (const type of this.acceptTypes) {
                    if (
                        type === file.type ||
                        (type.indexOf('.') === 0 && type === '.' + file.type.split('/')[1])
                    ) {
                        return true;
                    }
                }

                return false;
            },
        },
    };
</script>