<script>
    export default {
        name: 'CollapsibleGroup',

        data() {
            return {
                blocks: [],
                activeBlock: null,
            };
        },

        provide() {
            return {
                collapsibleGroup: {
                    activeBlock: () => this.activeBlock,
                    registerBlock: this.registerBlock,
                    setActiveBlock: this.setActiveBlock,
                },
            };
        },

        render(createElement) {
            return createElement('div', [...this.$slots.default]);
        },

        destroyed() {
            this.blocks = [];
        },

        methods: {
            registerBlock(block) {
                if (this.blocks.indexOf(block) < 0) {
                    this.blocks.push(block);

                    block.$once('hook:destroyed', () => {
                        this.unregisterBlock(block);
                    });
                }
            },

            unregisterBlock(block) {
                this.blocks = this.blocks.slice().filter((t) => t !== block);
            },

            setActiveBlock(block) {
                this.activeBlock = block;
            },
        },
    };
</script>
