<template>
    <Header :links="links" :customer="customer" :store="store" />
</template>

<script>
    import Header from './Header';

    export default {
        name: 'HeaderContainer',

        components: { Header },

        data() {
            return {
                store: !!this.$scope.STORE,
                links: this.$scope.STORE
                    ? [
                          {
                              id: '1',
                              label: 'Loja',
                              href: '/' + this.$scope.STORE.url + '/',
                          },
                          {
                              id: '2',
                              label: 'Contato',
                              href: '/' + this.$scope.STORE.url + '/fale-conosco',
                          },

                          // {
                          //     id: '2',
                          //     label: 'Sobre',
                          //     href: '/' + this.$scope.STORE.url + '/',
                          // },
                      ]
                    : this.$scope.HEADER_ITEMS,
                customer: this.$scope.CUSTOMER,
            };
        },
    };
</script>
