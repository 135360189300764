<template>
    <FieldWrapper :id="id" :error="error" :hint="hint" :label="label">
        <div
            :class="{ loading, 'no-right-icon': typed && typed.length > 0 }"
            class="cr-autocomplete"
        >
            <form ref="suggestionsAnchor" autocomplete="off" class="cr-autocomplete-input-wrapper">
                <input
                    :id="inputId"
                    ref="input"
                    v-model="typed"
                    :class="innerInputClass"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    type="text"
                    @focus="showResults = true"
                    @keydown.up="selectPrevious"
                    @keydown.down="selectNext"
                    @keydown.enter.prevent="onEnter"
                />
                <div
                    v-show="!disabled && !loading && typed && typed.length > 0"
                    class="input-clear"
                    @click="clearInput"
                ></div>
            </form>
            <Popover v-model="showResults" :anchor-element="$refs.suggestionsAnchor">
                <ul class="input-options">
                    <template v-for="(item, index) in suggestions">
                        <InputListItem
                            :key="itemValueKey ? item[itemValueKey] : index"
                            :class="{ selected: index === selectedIndex }"
                            :item="item"
                            :subtitle="getItemSubtitle(item)"
                            :title="getItemLabel(item)"
                            @mousedown.native="onItemClicked(index)"
                        >
                            <slot v-if="$scopedSlots.default" :item="item" />
                        </InputListItem>
                        <hr
                            v-if="
                                index < items.length - 1 &&
                                getItemGroup(item) !== getItemGroup(items[index + 1])
                            "
                            :key="'separator-' + (itemValueKey ? item[itemValueKey] : index)"
                            class="my-0"
                        />
                    </template>
                </ul>
            </Popover>
        </div>
    </FieldWrapper>
</template>

<script>
    import { fieldWrapperProps } from '../../../helpers/input';
    import FieldWrapper from '../field-wrapper/FieldWrapper';
    import Popover from '../../surface/popover/Popover';
    import InputListItem from '../input-list-item/InputListItem';
    import { getDeepProp } from '../../../helpers/object';

    export default {
        name: 'BaseAutocomplete',

        components: { InputListItem, Popover, FieldWrapper },

        props: {
            ...fieldWrapperProps,
            inputId: String,
            placeholder: String,
            items: Array,
            itemTitleKey: String,
            itemSubtitleKey: String,
            itemValueKey: String,
            groupProp: String,
            disabled: Boolean,
            inputClass: [String, Object],
            value: {
                default: null,
            },
            autoCleanErrors: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                loading: false,
                typed: null,

                results: null,
                resultsCache: {},
                showResults: false,

                innerSelectedItem: null,
                selectedIndex: -1,
            };
        },

        created() {
            this.updateResults(this.items);
            this.selectValue(this.value);
        },

        beforeDestroy() {
            if (this.isListenerRegistered) {
                this.stopListeningScroll();
            }
        },

        computed: {
            suggestions() {
                // if a valid item was selected, show all the items
                return !this.typed ||
                    this.typed.toString().trim().length === 0 ||
                    this.innerSelectedItem
                    ? this.items
                    : this.results;
            },

            innerInputClass() {
                if (this.error) {
                    if (typeof this.inputClass === 'string') {
                        return this.inputClass + ' input-danger';
                    } else {
                        return {
                            'input-danger': this.error,
                            ...this.inputClass,
                        };
                    }
                } else {
                    return this.inputClass;
                }
            },

            formattedLabel() {
                return this.label.indexOf(':') > 0 ? this.label : `${this.label}:`;
            },
        },

        watch: {
            typed() {
                if (
                    this.innerSelectedItem &&
                    this.getItemLabel(this.innerSelectedItem) !== this.typed
                ) {
                    this.innerSelectedItem = null;
                }
            },

            value(to) {
                this.selectValue(to);
            },

            innerSelectedItem(to) {
                this.$emit('input', to ? this.getItemValue(to) : null);
                if (this.itemTitleKey) {
                    this.$emit('item-select', {
                        item: to,
                        label: to ? this.getItemLabel(to) : null,
                        value: to ? this.getItemValue(to) : null,
                    });
                }
                if (this.error && this.autoCleanErrors) {
                    this.$emit('update:error', null);
                }
            },

            items(to) {
                this.updateResults(to);
            },

            results() {
                this.selectedIndex = -1;
                if (this.value && !this.typed) {
                    this.selectValue(this.value);
                }
            },

            disabled() {
                this.showResults = false;
            },
        },

        methods: {
            getItemLabel(item) {
                if (this.itemTitleKey) {
                    return getDeepProp(item, this.itemTitleKey);
                }

                return item.toString();
            },

            getItemValue(item) {
                if (this.itemValueKey) {
                    return getDeepProp(item, this.itemValueKey);
                }

                return item;
            },

            getItemSubtitle(item) {
                if (this.itemSubtitleKey) {
                    return getDeepProp(item, this.itemSubtitleKey);
                }
            },

            getItemGroup(item) {
                if (this.groupProp) {
                    return getDeepProp(item, this.groupProp);
                } else {
                    return null;
                }
            },

            clearInput() {
                this.typed = '';
                this.$refs.input.focus();
            },

            onEnter() {
                if (this.suggestions && this.suggestions.length > 0) {
                    this.selectItem(this.suggestions[Math.max(0, this.selectedIndex)]);
                } else {
                    this.selectItem(null);
                }
            },

            selectPrevious() {
                this.selectedIndex = Math.max(0, this.selectedIndex - 1);
            },

            selectNext() {
                this.selectedIndex = Math.min(
                    this.suggestions ? this.suggestions.length - 1 : 0,
                    this.selectedIndex + 1
                );
            },

            onItemClicked(index) {
                this.showResults = false;
                this.selectedIndex = index;
                this.selectItem(this.suggestions[this.selectedIndex]);
            },

            selectValue(value) {
                if (this.items) {
                    if (this.itemValueKey) {
                        this.selectItem(this.items.find((i) => i[this.itemValueKey] === value));
                    } else {
                        this.selectItem(value);
                    }
                }
            },

            selectItem(item) {
                if (item) {
                    this.innerSelectedItem = item;
                    this.typed = this.getItemLabel(this.innerSelectedItem);
                } else {
                    this.innerSelectedItem = null;
                    this.typed = '';
                }
            },

            updateResults(results) {
                if (results) {
                    this.results = [...results];
                    this.resultsCache[this.typed] = [...results];
                } else {
                    this.results = [];
                    this.resultsCache[this.typed] = [];
                }

                this.loading = false;
            },
        },
    };
</script>
