<template>
    <nav>
        <HeaderDesktop :links="links" :customer="customer" />
        <HeaderMobile :links="links" :customer="customer" />
        <HeaderCartDrawer :customer="customer" />

        <section id="black-friday-23" v-if="getAction().action === 1">
            <div class="countdown-bf" style="height: auto; padding-bottom: 0.25rem">
                <div class="content">
                    <div class="center-block countdown-align" v-if="isWeekend()">
                        <picture>
                            <source
                                srcset="
                                    https://uma-penca.imgix.net/pages/black-friday/contador-bf-mobile.png?auto=compress,format&q=65
                                "
                                media="(max-width: 500px)"
                            />
                            <img
                                src="https://uma-penca.imgix.net/pages/black-friday/contador-bf-desktop.png?auto=compress,format&q=65"
                                alt="Black Friday"
                                class="img-responsive pt-1"
                            />
                        </picture>
                        <div>
                            <strong class="number-count">
                                <Countdown :deadline="new Date('2023-11-26T23:59:59')"></Countdown>
                            </strong>
                        </div>
                    </div>
                    <div class="center-block" v-else>
                        <picture>
                            <source
                                srcset="
                                    https://uma-penca.imgix.net/pages/black-friday/black-friday-mobile.png?auto=compress,format&q=65
                                "
                                media="(max-width: 500px)"
                            />
                            <img
                                src="https://uma-penca.imgix.net/pages/black-friday/black-friday-2023-personalizados.png?auto=compress,format&q=65"
                                alt="Black Friday"
                                class="img-responsive pt-1"
                            />
                        </picture>
                    </div>
                </div>
            </div>
        </section>
    </nav>
</template>

<script>
    import HeaderDesktop from '../header-desktop/HeaderDesktop';
    import HeaderMobile from '../header-mobile/HeaderMobile';
    import HeaderCartDrawer from '../header-cart-drawer/HeaderCartDrawer';
    import Countdown from '../../../common/Countdown.vue';

    export default {
        name: 'Header',
        components: {
            HeaderDesktop,
            HeaderMobile,
            HeaderCartDrawer,
            Countdown,
        },
        props: {
            links: {
                type: Array,
                required: true,
            },
            customer: Object,
            store: Boolean,
        },
        methods: {
            getAction() {
                const today = new Date();
                const dateRanges = [
                    {
                        startDate: new Date(2023, 10, 17, 0, 0, 0),
                        endDate: new Date(2023, 10, 23, 26, 59, 59),
                        action: 1,
                    },
                ];

                for (const range of dateRanges) {
                    if (today >= range.startDate && today <= range.endDate) {
                        return range;
                    }
                }

                return false;
            },

            isWeekend() {
                console.log('teste');
                var dataInicio = new Date(2023, 10, 24, 0, 0, 0);
                var dataFim = new Date(2023, 10, 26, 0, 0, 0);
                var dataAtual = new Date();
                console.log(dataAtual >= dataInicio && dataAtual <= dataFim);
                return dataAtual >= dataInicio && dataAtual <= dataFim;
            },
        },
    };
</script>
