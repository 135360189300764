<template>
    <FieldWrapper
        :id="id"
        :add-on-after="addOnAfter"
        :add-on-before="addOnBefore"
        :error="error"
        :hint="hint"
        :label="label"
    >
        <cleave
            :id="id"
            ref="inputFieldRef"
            :autocomplete="autocomplete"
            :class="{ 'input-danger': error }"
            :disabled="disabled"
            :options="options"
            :placeholder="placeholder"
            :raw="raw"
            :value="modelValue"
            :model-value="modelValue"
            @blur="$emit('blur', $event)"
            @input="onInput"
            @update:modelValue="onInput"
        />
    </FieldWrapper>
</template>

<script>
    import FieldWrapper from '../field-wrapper/FieldWrapper';
    import { fieldWrapperProps } from '../../../helpers/input';
    import Field from '../field/Field';

    export default {
        compatConfig: { COMPONENT_V_MODEL: false },

        name: 'CleaveField',

        extends: Field,

        components: { FieldWrapper },

        props: {
            ...fieldWrapperProps,
            options: Object,
            raw: {
                type: Boolean,
                default: true,
            },
            type: {
                type: String,
                default: 'text',
            },
        },

        methods: {
            onInput(newValue) {
                if (this.error && this.autoCleanErrors) {
                    this.$emit('update:error', null);
                }

                this.$emit('update:modelValue', newValue);
            },
        },
    };
</script>
