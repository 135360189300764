import './polyfill';
import './header';
import {
    addDataLayer,
    EventBus,
    getScope,
    readCookie,
    reamazeClickHandler,
    setCookie,
} from './common';
import Vue from './vue-common';
import HeaderContainer from '../components/core/navigation/header/HeaderContainer';
import cr$ from '../../submodules/src/script/crquery';
import { SHOW_MOBILE_MENU_EVENT } from './events';

window.cr$ = cr$;

cr$.ready(function () {
    // LGPD
    const POLICY_VERSION = 'v1';
    const confirmedPolicyVersion = readCookie('_upPolicy');
    const policyInfoPopUp = cr$.byId('policyInfoPopUp');

    if (policyInfoPopUp.exists() && confirmedPolicyVersion !== POLICY_VERSION) {
        policyInfoPopUp.css('display', 'block');

        cr$.byId('confirmPolicyButton').click(() => {
            policyInfoPopUp.css('display', 'none');
            let d = new Date();
            d.setTime(d.getTime() + 25 * 30 * 24 * 60 * 60 * 1000);
            setCookie('_upPolicy', POLICY_VERSION, d.toUTCString(), '/');
        });
    }

    window.dataLayer = window.dataLayer || [];

    let customerId = getScope('id');
    let hashEmail = getScope('email_hash');
    let email = getScope('email');
    let userName = getScope('user_name');
    let userAvatar = getScope('user_avatar');
    let reamazeAuthKey = getScope('reamaze_authKey');
    let isRegularCustomer = getScope('is_regular_customer');

    addDataLayer(
        customerId,
        email,
        hashEmail,
        userName,
        userAvatar,
        reamazeAuthKey,
        !isRegularCustomer ? 2 : 1
    );

    // Reamaze click
    const reamazeElm = cr$.byId('olark-tab');
    reamazeElm.click(reamazeClickHandler);

    cr$.byId('onlineChatReamaze').click(() => reamazeElm.click());

    const headerVue = cr$.byId('header-vue');
    if (headerVue.exists()) {
        new Vue({
            render: (h) => h(HeaderContainer),
        }).$mount('#header-vue');
    }
});
