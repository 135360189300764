import GlobalScope from '../plugin/global-scope';
import Filters from '../plugin/filters';
import VueLazy from '../directive/lazy';
import Tooltip from '../directive/tooltip';
import clickOutsideDirective from '../directive/click-ouside';
import VueAxios from '../plugin/axios';
import { http, api } from './common';
import Services from '../plugin/services';
import Cleave from 'vue-cleave-component';
import LazyLoad from '../plugin/lazyload';
import VueI18n from 'vue-i18n';

const setupVueInstance = (Vue) => {
    Vue.use(GlobalScope);
    Vue.use(Filters);
    Vue.use(Services);
    Vue.use(VueAxios, { http, api });
    Vue.use(LazyLoad, {
        update: () => {},
    });
    Vue.use(Cleave);
    Vue.use(clickOutsideDirective);
    Vue.use(VueI18n);

    VueLazy(Vue);
    Tooltip(Vue);
};

export default setupVueInstance;
