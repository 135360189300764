export default () => ({
    pt: {
        _address: {
            selectAndDeliverHere: 'Salvar e entregar aqui',
            selectOrCreate: 'Selecione um endereço para a entrega ou cadastre um novo',
            createNew: 'Cadastrar novo endereço',
            recipient: 'Destinatário',
            areSureDelete: 'Deseja realmente excluir o endereço',
            enterAddressToProceed: 'Informe um endereço para prosseguir',
            errorDeleting: 'Não foi possível excluir o endereço.',
            successfullyDeletedX: 'Endereço {0} excluído com sucesso.',
            zipCodeNotFoundOrUnavailable: 'CEP não encontrado ou indisponível para entrega.',
            errorCalculatingShippingTryAgain:
                'Não foi possível calcular o frete. Recarregue a página e tente novamente.',
        },
    },
    en: {
        _address: {
            selectAndDeliverHere: 'Save and deliver here',
            selectOrCreate: 'Select a delivery address or register a new one',
            createNew: 'Register new address',
            recipient: 'Recipient',
            areSureDelete: 'Do you really want to delete the address',
            enterAddressToProceed: 'Enter an address to proceed',
            errorDeleting: 'Could not delete address.',
            successfullyDeletedX: 'Address {0} successfully deleted.',
            zipCodeNotFoundOrUnavailable: 'Zip code not found or unavailable for shipping.',
            errorCalculatingShippingTryAgain:
                'Failed to calculate the shipping. Refresh the page and try again.',
        },
    },
});
