<template>
    <div
        :class="wrapperClass"
        :data-testid="dataTestId"
        aria-hidden="false"
        aria-label="Carregando"
    >
        <div :class="spinnerClass"></div>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'Spinner',

        props: {
            small: Boolean,
            inline: Boolean,
            dataTestId: String,
        },

        computed: {
            spinnerClass() {
                if (this.$scopedSlots.default || this.inline) {
                    return 'cr-spinner-inline d-inline-block mr-1 ml-0 ';
                } else if (this.small) {
                    return 'cr-spinner-small';
                } else {
                    return 'cr-spinner';
                }
            },

            wrapperClass() {
                if (this.$scopedSlots.default || this.inline) {
                    return 'd-inline-flex flex-align-center';
                } else if (this.small) {
                    return 'px-2 py-2';
                } else {
                    return 'px py';
                }
            },
        },
    };
</script>
