<script>
    import { fieldWrapperProps } from '../../../helpers/input';

    export default {
        name: 'Field',

        props: {
            ...fieldWrapperProps,

            id: String,
            name: String,
            disabled: {
                type: Boolean,
                default: null,
            },
            placeholder: String,
            maxlength: [String, Number],
            readonly: Boolean,
            autocomplete: String,

            inputClasses: String,

            modelValue: {
                default: null,
            },

            autoCleanErrors: {
                type: Boolean,
                default: true,
            },
        },

        model: {
            prop: 'modelValue',
            event: 'update:modelValue',
        },

        emits: ['update:modelValue'],

        data() {
            let innerId;

            if (this.id) {
                innerId = this.id;
            } else
                do {
                    innerId = `field-${Math.floor(Math.random() * 1000000)}`;
                } while (document.getElementById(innerId));

            return { innerId };
        },

        computed: {
            getInputClasses() {
                let classes = this.inputClasses ? this.inputClasses.split(' ') : [];
                if (this.error) classes.push('input-danger');
                return classes;
            },
        },

        methods: {
            getInput() {
                return this.$refs.inputFieldRef.$el || this.$refs.inputFieldRef;
            },

            focus() {
                this.getInput().focus();
            },

            blur() {
                this.getInput().blur();
            },

            selectText() {
                this.getInput().setSelectionRange(0, (this.getInput().value || '').length);
            },
        },
    };
</script>
