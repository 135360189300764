<template>
    <div v-if="indicatorLeftPosition !== null" class="text-center">
        <div
            :style="`width: ${barWidth}px; height: 3px`"
            class="horizontal-scrollbar d-inline-block bg-default-1 rounded-full"
        >
            <div class="pos-relative h-full" style="margin: 0 14px">
                <div
                    :style="indicatorStyle"
                    class="horizontal-scrollbar-thumb pos-absolute bg-info-5 rounded-full h-full"
                />
            </div>
        </div>
    </div>
</template>

<script>
    const BAR_WIDTH = 90;
    const THUMB_WIDTH = 28;

    export default {
        name: 'HorizontalScrollbar',

        props: {
            scrollEvent: Event,
        },

        data() {
            return {
                barWidth: BAR_WIDTH,
                indicatorLeftPosition: 0,
            };
        },

        watch: {
            scrollEvent(event) {
                const scrollRange = event.target.scrollWidth - event.target.clientWidth;
                if (event.target.scrollWidth > event.target.clientWidth) {
                    this.indicatorLeftPosition = (event.target.scrollLeft / scrollRange) * 100;
                } else {
                    this.indicatorLeftPosition = null;
                }
            },
        },

        computed: {
            indicatorStyle() {
                return {
                    width: THUMB_WIDTH + 'px',
                    left: this.indicatorLeftPosition + '%',
                    transform: 'translateX(-14px)',
                };
            },
        },
    };
</script>
