<template>
    <nav id="header-links" :class="!scrollHeader ? 'header-links' : 'header-links-scroll'">
        <ul class="no-list d-flex text-uppercase">
            <li v-for="link in links" :key="link.id" class="mx">
                <a
                    :href="link.href"
                    :target="link.href[0] === 'h' ? '_blank' : ''"
                    class="header-text-color"
                >
                    {{ link.label }}
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: 'HeaderLinks',
        props: {
            links: {
                type: Array,
                required: true,
            },
            scrollHeader: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
