import Hammer from 'hammerjs';

/**
 * Responsável pelo comportamento de slides utilizando hammerjs
 * Suporte a:
 *  loop
 *  paginação
 *  movimento horizontal ou vertical
 *  exibição de vários slides por vez
 *  ajuste de largura
 */
const Slider = function (wrapperEl) {
    //---- PARÂMETROS ----//

    const SLIDE_CLASS_PREFIX = 'slide';
    const ACTIVE_PAGINATION_BULLET_CLASS = 'active';
    const PAGINATION_CONTAINER_CLASS = 'slides-pagination';
    const PAGINATION_BULLET_CLASS = 'slider-bullet';
    const SLIDES_CONTAINER_CLASS = '.slides-container';
    const SLIDE_CLASS = '.slide';

    /** Valores padrão de configuração **/
    const defaults = {
        orientation: 'horizontal',
        sensitivity: 25, // % do necessária no movimento pra finalizar o swipe
        loop: false,
        adaptOnResize: true, // ajusta tamanho dos slides ao redimensionar janela
        enableArrows: false,
        enableBullets: false,
        slidesPerView: 1, // quantos slides são exibidos por vez
        startAt: 0,
        autoplayInterval: 0,
        fitSlidesToParent: false,
        centerSlides: true,
        enableGestures: true,
    };

    this.wrapperEl = wrapperEl;

    /** Índice do slide atual (leva os clones em consideração, i.e. não é o índice original do slide ativo) **/
    this.activeSlideIndex = 0;
    this.previousActiveSlideIndex = 0;

    /** Quantidade de clones a serem criados nas "extremidades" quando loop é habilitado **/
    let clonesCount = 3;

    let autoplayInterval = null;
    let reverseAutoplay = false;

    //----- MÉTODOS PRIVADOS -----//

    const getComputedWidth = (el) => {
        let width = el.offsetWidth;
        const computedStyle = window.getComputedStyle(el, null);

        const paddingLeft = parseInt(computedStyle.getPropertyValue('paddingLeft'));
        if (!isNaN(paddingLeft)) width -= paddingLeft;

        const paddingRight = parseInt(computedStyle.getPropertyValue('paddingRight'));
        if (!isNaN(paddingRight)) width -= paddingRight;

        const padding = computedStyle.getPropertyValue('padding');

        let patternTest = /\d+px (\d+)px \d+px (\d+)px/.exec(padding);

        if (patternTest && patternTest.length === 3) {
            width -= parseInt(patternTest[1]) + parseInt(patternTest[2]);
        } else {
            patternTest = /\d+px (\d+)px/.exec(padding);

            if (patternTest && patternTest.length === 2) width -= 2 * parseInt(patternTest[1]);
        }

        return width;
    };

    const onWindowResize = () => {
        const previousSlidesPerView = this.responsiveSettings.slidesPerView;

        detectResponsiveSettings();

        if (previousSlidesPerView !== this.responsiveSettings.slidesPerView) initPagination();

        initArrows();
        resizeSlides();
        updateGestures();

        if (this.slideCount - this.activeSlideIndex < this.responsiveSettings.slides) {
            doNavigation(this.slideCount - this.responsiveSettings.slides);
        }
    };

    const detectResponsiveSettings = () => {
        this.responsiveSettings = null;

        if (this.settings.responsive && this.settings.responsive.length) {
            for (let responsiveSettings of this.settings.responsive) {
                if (window.innerWidth >= responsiveSettings.minWidth) {
                    this.responsiveSettings = responsiveSettings.settings;
                    break;
                }
            }
        }

        if (!this.responsiveSettings) {
            this.responsiveSettings = this.settings;
        }
    };

    const resizeSlides = () => {
        if (
            this.responsiveSettings.enableArrows &&
            this.slideCount > this.responsiveSettings.slidesPerView
        )
            getContainer().classList.add('slider-arrows-padding');
        else getContainer().classList.remove('slider-arrows-padding');

        const slides = this.sliderEl.querySelectorAll(SLIDE_CLASS);

        let width, height;

        if (this.responsiveSettings.orientation === 'vertical') {
            height = (slides[0] || {}).offsetHeight;
            if (height === 0 || !height) return;
            getSlidesContainer().style.height =
                height * (this.slideCount / this.responsiveSettings.slidesPerView) + 'px';
        } else {
            width = getComputedWidth(getContainer());
            if (width === 0) return;
            getSlidesContainer().style.width =
                width * (this.slideCount / this.responsiveSettings.slidesPerView) + 'px';
        }

        getSlidesContainer().parentElement.style.height =
            (this.responsiveSettings.orientation === 'vertical'
                ? height * this.responsiveSettings.slidesPerView
                : height) + 'px';

        for (const slide of slides) {
            if (this.responsiveSettings.fitSlidesToParent) {
                slide.style.maxWidth = 100 / this.slideCount + '%';
            } else {
                if (this.responsiveSettings.width) slide.style.width = width + 'px';
                if (this.responsiveSettings.height) slide.style.height = height + 'px';
            }
        }
    };

    const getSlideOriginalIndex = (slideIndex, slides = getSlides()) => {
        for (const classN of slides[slideIndex].classList) {
            const matches = classN.match(new RegExp(`${SLIDE_CLASS_PREFIX}-(.+)`));
            if (matches) return parseInt(matches[1]);
        }
    };

    const findSlideIndexForward = (originalIndex, slides = getSlides()) => {
        for (let i = this.activeSlideIndex + 1; i < slides.length; i++) {
            if (getSlideOriginalIndex(i, slides) === originalIndex) {
                return i;
            }
        }
    };

    const findSlideIndexBackward = (originalIndex, slides = getSlides()) => {
        for (let i = this.activeSlideIndex - 1; i >= 0; i--) {
            if (getSlideOriginalIndex(i, slides) === originalIndex) {
                return i;
            }
        }
    };

    const initArrows = () => {
        let rightArrow = getContainer().querySelector('.slider-arrow-right');
        let leftArrow = getContainer().querySelector('.slider-arrow-left');

        if (
            this.responsiveSettings.enableArrows &&
            this.slideCount > this.responsiveSettings.slidesPerView
        ) {
            if (rightArrow) {
                if (
                    this.activeSlideIndex >=
                    this.slideCount - (this.slideCount % this.responsiveSettings.slidesPerView)
                )
                    rightArrow.classList.add('invisible');
                else rightArrow.classList.remove('invisible');
            } else {
                const rightArrowIcon = document.createElement('i');
                rightArrowIcon.classList.add('icon-cr');
                rightArrowIcon.classList.add('icon-chevron-right');

                rightArrow = document.createElement('div');
                rightArrow.classList.add('slider-arrow-right');
                rightArrow.appendChild(rightArrowIcon);
                rightArrow.onclick = () =>
                    doNavigation(this.activeSlideIndex + this.responsiveSettings.slidesPerView);

                if (
                    !this.responsiveSettings.loop &&
                    this.activeSlideIndex >= this.slideCount - this.responsiveSettings.slidesPerView
                )
                    rightArrow.classList.add('invisible');

                getContainer().appendChild(rightArrow);
            }

            if (leftArrow) {
                if (this.activeSlideIndex === 0) leftArrow.classList.add('invisible');
                else leftArrow.classList.remove('invisible');
            } else {
                const leftArrowIcon = document.createElement('i');
                leftArrowIcon.classList.add('icon-cr');
                leftArrowIcon.classList.add('icon-chevron-left');

                leftArrow = document.createElement('div');
                leftArrow.classList.add('slider-arrow-left');
                leftArrow.appendChild(leftArrowIcon);
                leftArrow.onclick = () =>
                    doNavigation(this.activeSlideIndex - this.responsiveSettings.slidesPerView);

                if (!this.responsiveSettings.loop && this.activeSlideIndex === 0)
                    leftArrow.classList.add('invisible');

                getContainer().appendChild(leftArrow);
            }
        } else {
            if (leftArrow) leftArrow.classList.add('invisible');
            if (rightArrow) rightArrow.classList.add('invisible');
        }
    };

    const updateArrows = () => {
        if (
            !this.responsiveSettings.loop &&
            this.responsiveSettings.enableArrows &&
            this.slideCount > this.responsiveSettings.slidesPerView
        ) {
            const arrowRight = getContainer().querySelector('.slider-arrow-right');
            const arrowLeft = getContainer().querySelector('.slider-arrow-left');

            if (this.activeSlideIndex === 0) {
                arrowLeft.classList.add('invisible');
            } else {
                arrowLeft.classList.remove('invisible');
            }

            if (this.activeSlideIndex === this.slideCount - this.responsiveSettings.slidesPerView) {
                arrowRight.classList.add('invisible');
            } else {
                arrowRight.classList.remove('invisible');
            }
        }
    };

    const initPagination = () => {
        this.responsiveSettings.enableBullets =
            this.responsiveSettings.enableBullets &&
            this.slideCount / this.responsiveSettings.slidesPerView > 1;

        let paginationContainer = getPaginationContainer();
        if (paginationContainer) {
            paginationContainer.innerHTML = '';
        } else if (this.responsiveSettings.enableBullets) {
            paginationContainer = document.createElement('p');
            paginationContainer.classList.add(PAGINATION_CONTAINER_CLASS);
            getContainer().appendChild(paginationContainer);
        }

        if (!this.responsiveSettings.enableBullets) return;

        const bulletsCount = Math.ceil(
            (this.slideCount - this.clonesCreated) / this.responsiveSettings.slidesPerView
        );
        const activeSlideOriginalIndex = getSlideOriginalIndex(this.activeSlideIndex);
        const activeBulletIndex = Math.ceil(
            activeSlideOriginalIndex / this.responsiveSettings.slidesPerView
        );

        for (let i = 0; i < bulletsCount; i++) {
            const bulletDiv = document.createElement('div');
            const iconSpan = document.createElement('span');

            bulletDiv.classList.add(PAGINATION_BULLET_CLASS);
            bulletDiv.appendChild(iconSpan);

            const nConst = i;
            bulletDiv.onclick = () => {
                let goTo = nConst * this.responsiveSettings.slidesPerView;
                if (this.slideCount - goTo < this.responsiveSettings.slidesPerView)
                    goTo = this.slideCount - this.responsiveSettings.slidesPerView;
                this.goTo(goTo);
                return false;
            };

            if (i === activeBulletIndex) {
                bulletDiv.classList.add(ACTIVE_PAGINATION_BULLET_CLASS);
            } else {
                bulletDiv.classList.remove(ACTIVE_PAGINATION_BULLET_CLASS);
            }

            paginationContainer.appendChild(bulletDiv);
        }
    };

    const updatePagination = () => {
        if (this.responsiveSettings.enableBullets) {
            const bullets = getPaginationBullets();
            const previouslyActiveSlideOriginalIndex = getSlideOriginalIndex(
                this.previousActiveSlideIndex
            );
            const activeSlideOriginalIndex = getSlideOriginalIndex(this.activeSlideIndex);

            const previouslyActiveBulletIndex = Math.ceil(
                previouslyActiveSlideOriginalIndex / this.responsiveSettings.slidesPerView
            );
            const activeBulletIndex = Math.ceil(
                activeSlideOriginalIndex / this.responsiveSettings.slidesPerView
            );

            bullets[previouslyActiveBulletIndex].classList.remove(ACTIVE_PAGINATION_BULLET_CLASS);
            bullets[activeBulletIndex].classList.add(ACTIVE_PAGINATION_BULLET_CLASS);
        }
    };

    /** Código base obtido em https://blog.envylabs.com/build-your-own-touch-slider-with-hammerjs-af99665d2869 **/
    const onPanHandler = (e) => {
        // se a orientação do slider é vertical e o movimento foi horizontal, ignora
        if (
            (e.additionalEvent === 'panleft' || e.additionalEvent === 'panright') &&
            this.responsiveSettings.orientation === 'vertical'
        )
            return;

        // se a orientação do slider é horizontal e o movimento foi vertical, ignora
        if (
            (e.additionalEvent === 'pandown' || e.additionalEvent === 'panup') &&
            this.responsiveSettings.orientation !== 'vertical'
        )
            return;

        clearInterval(autoplayInterval);
        autoplayInterval = null;

        const delta = this.responsiveSettings.orientation === 'vertical' ? e.deltaY : e.deltaX;
        const velocity =
            this.responsiveSettings.orientation === 'vertical' ? e.velocityY : e.velocityX;

        // Calculate pixel movements into 1:1 screen percents so gestures track with motion
        const percentage =
            ((100 / (this.slideCount / this.responsiveSettings.slidesPerView)) * delta) /
            (this.responsiveSettings.orientation === 'vertical'
                ? getContainer().offsetHeight
                : window.innerWidth);

        // Multiply percent by # of slide we’re on
        const percentageCalculated = percentage - (100 / this.slideCount) * this.activeSlideIndex;

        // Apply transformation
        this.sliderEl.style.transform = `${this.cssTranslateProperty}(${
            percentageCalculated + getOffsetTranslation()
        }%)`;

        getSlidesContainer().classList.add('disabled-links');

        // Snap to slide when done
        if (e.isFinal) {
            if (velocity === 0) {
                doNavigation(this.activeSlideIndex);
            } else {
                if (velocity > 1) {
                    doNavigation(this.activeSlideIndex - this.responsiveSettings.slidesPerView);
                } else if (velocity < -1) {
                    doNavigation(this.activeSlideIndex + this.responsiveSettings.slidesPerView);
                } else {
                    if (percentage <= -(this.responsiveSettings.sensitivity / this.slideCount))
                        doNavigation(this.activeSlideIndex + this.responsiveSettings.slidesPerView);
                    else if (percentage >= this.responsiveSettings.sensitivity / this.slideCount)
                        doNavigation(this.activeSlideIndex - this.responsiveSettings.slidesPerView);
                    else doNavigation(this.activeSlideIndex);
                }
            }
        }
    };

    const cloneSlide = (elem) => {
        let clone = document.createElement('div');
        clone.innerHTML = elem.outerHTML;
        disableDrag(clone.childNodes[0]);
        return clone.childNodes[0];
    };

    // cálculo para centralizar o slide caso ele seja menor que a largura do wrapper
    const getOffsetTranslation = () => {
        if (
            !this.responsiveSettings.fitSlidesToParent &&
            this.responsiveSettings.orientation === 'horizontal'
        ) {
            const wrapperWidth = getComputedWidth(getContainer());
            const slideWidth =
                getSlides()[this.activeSlideIndex].offsetWidth *
                this.responsiveSettings.slidesPerView;
            const centeredTranslation =
                (((1 - slideWidth / wrapperWidth) / 2) * 100) /
                (this.slideCount / this.responsiveSettings.slidesPerView);

            if (this.responsiveSettings.centerSlides) {
                return (
                    centeredTranslation *
                        2 *
                        (this.activeSlideIndex / this.responsiveSettings.slidesPerView) +
                    centeredTranslation
                );
            } else {
                return (
                    centeredTranslation *
                    2 *
                    (this.activeSlideIndex / this.responsiveSettings.slidesPerView)
                );
            }
        } else return 0;
    };

    const translateSlide = () => {
        const percentage = -(100 / this.slideCount) * this.activeSlideIndex;
        this.sliderEl.style.transform = `${this.cssTranslateProperty}(${
            percentage + getOffsetTranslation()
        }%)`;
    };

    /**
     * Desabilita o arrastar no IE9+, Edge e Firefox
     */
    const disableDrag = (slideEl) => {
        slideEl.setAttribute('unselectable', 'on'); // IE9+ e Edge
        slideEl.ondragstart = () => false; // Edge e Firefox
    };

    /**
     * Função para montar os containers dos slides
     */
    const prepareSlides = () => {
        if (getContainer().className.indexOf('slider-ready') >= 0) return;

        const slidesNodes = getContainer().childNodes;

        const slidesWrapper = document.createElement('div');
        slidesWrapper.classList.add('slides-container');
        if (this.responsiveSettings.orientation === 'vertical')
            slidesWrapper.classList.add('slides-container-vertical');

        const slidesContainer = document.createElement('div');
        slidesContainer.classList.add('slides');
        if (this.responsiveSettings.fitSlidesToParent)
            slidesContainer.classList.add('slides-flex-width');

        for (let slideNode of slidesNodes) {
            if (slideNode.outerHTML !== undefined) {
                const slideContainer = document.createElement('div');
                slideContainer.classList.add('slide');

                if (this.responsiveSettings.lazyImages) {
                    const slideLazyWrapper = document.createElement('div');
                    slideLazyWrapper.classList.add('slide-lazy-wrapper');
                    slideLazyWrapper.innerHTML = slideNode.outerHTML;
                    slideContainer.appendChild(slideLazyWrapper);
                } else {
                    slideContainer.innerHTML = slideNode.outerHTML;
                }

                slidesContainer.appendChild(slideContainer);

                slideNode.classList.add('slider-remove');
            }
        }

        for (const slide of slidesContainer.childNodes) disableDrag(slide);

        slidesWrapper.appendChild(slidesContainer);
        getContainer().appendChild(slidesWrapper);
    };

    const initSlides = () => {
        const elementsToRemove = getContainer().querySelectorAll('.slider-remove');
        for (let elem of elementsToRemove) {
            if (elem.parentElement == getContainer()) getContainer().removeChild(elem);
        }

        const slides = getSlides();

        for (let i = 0; i < slides.length; i++) {
            slides[i].classList.add(`${SLIDE_CLASS_PREFIX}-${i}`);
        }

        if (this.responsiveSettings.loop) {
            let clonedBefore = 0;
            while (clonedBefore < clonesCount - this.responsiveSettings.startAt) {
                const clone = cloneSlide(slides[slides.length - clonedBefore - 1]);
                this.sliderEl.prepend(clone);
                clonedBefore++;
            }

            let clonedAfter = 0;
            while (
                clonedAfter <
                clonesCount - this.slideCount - this.responsiveSettings.slidesPerView
            ) {
                const clone = cloneSlide(slides[clonedAfter]);
                this.sliderEl.append(clone);
                clonedAfter++;
            }

            this.clonesCreated = clonedBefore + clonedAfter;
            this.slideCount += this.clonesCreated;
            this.activeSlideIndex = this.responsiveSettings.startAt + clonedBefore;
        } else {
            this.clonesCreated = 0;
        }

        if (this.responsiveSettings.fitSlidesToParent) {
            for (let slide of getSlides()) {
                slide.style.maxWidth = 100 / this.slideCount + '%';
            }
        }
    };

    const fixLoop = () => {
        if (this.activeSlideIndex === this.previousActiveSlideIndex) return;

        const slides = getSlides();
        if (slides.length > 0) {
            if (this.activeSlideIndex > this.previousActiveSlideIndex) {
                this.activeSlideIndex--;
                this.sliderEl.removeChild(slides[0]);
                this.sliderEl.append(cloneSlide(slides[clonesCount]));
            } else {
                this.activeSlideIndex++;
                this.sliderEl.removeChild(slides[slides.length - 1]);
                this.sliderEl.prepend(cloneSlide(slides[slides.length - (clonesCount + 1)]));
            }
        }

        translateSlide();
    };

    /**
     * Navega até um slide
     * @param number Índice do slide levando em conta os clones
     */
    const doNavigation = (number) => {
        // Evita animações como ir além do último
        // todo: se isso ocorrer com loop ativado deveria ajustar os clones
        this.previousActiveSlideIndex = this.activeSlideIndex;
        if (number < 0) {
            this.activeSlideIndex = 0;
        } else if (number > this.slideCount - this.responsiveSettings.slidesPerView) {
            this.activeSlideIndex = this.slideCount - this.responsiveSettings.slidesPerView;
        } else {
            this.activeSlideIndex = number;
        }

        this.sliderEl.classList.add('is-animating');
        translateSlide();
        clearTimeout(this.timer);

        const timeoutFunc = () => {
            getSlidesContainer().classList.remove('disabled-links');

            this.sliderEl.classList.remove('is-animating');

            if (this.responsiveSettings.loop) {
                fixLoop();
            }

            if (this.responsiveSettings.autoplayInterval > 0) {
                initAutoplay();
            }
        };

        this.timer = setTimeout(timeoutFunc, 400);

        updatePagination();
        updateArrows();
        reportNavigation();
    };

    const reportNavigation = () => {
        if (this.responsiveSettings.onNavigate) {
            this.responsiveSettings.onNavigate(
                this,
                getSlideOriginalIndex(this.activeSlideIndex),
                this.slideCount - this.clonesCreated
            );
        }
    };

    const initAutoplay = () => {
        if (!autoplayInterval) {
            autoplayInterval = setInterval(() => {
                if (!this.settings.loop) {
                    if (this.activeSlideIndex === this.slideCount - 1) reverseAutoplay = true;
                    else if (this.activeSlideIndex === 0) reverseAutoplay = false;
                }

                if (reverseAutoplay) doNavigation(this.activeSlideIndex - 1);
                else doNavigation(this.activeSlideIndex + 1);
            }, this.responsiveSettings.autoplayInterval);
        }
    };

    const validateSettings = (settings, defaults) => {
        settings = {
            orientation:
                settings.orientation !== undefined ? settings.orientation : defaults.orientation,
            loop: settings.loop !== undefined ? settings.loop : defaults.loop,
            sensitivity:
                settings.sensitivity !== undefined ? settings.sensitivity : defaults.sensitivity,
            adaptOnResize:
                settings.adaptOnResize !== undefined
                    ? settings.adaptOnResize
                    : defaults.adaptOnResize,
            enableArrows:
                settings.enableArrows !== undefined ? settings.enableArrows : defaults.enableArrows,
            enableBullets:
                settings.enableBullets !== undefined
                    ? settings.enableBullets
                    : defaults.enableBullets,
            slidesPerView:
                settings.slidesPerView !== undefined
                    ? settings.slidesPerView
                    : defaults.slidesPerView,
            startAt: settings.startAt !== undefined ? settings.startAt : defaults.startAt,
            autoplayInterval:
                settings.autoplayInterval !== undefined
                    ? settings.autoplayInterval
                    : defaults.autoplayInterval,
            fitSlidesToParent:
                settings.fitSlidesToParent !== undefined
                    ? settings.fitSlidesToParent
                    : defaults.fitSlidesToParent,
            centerSlides:
                settings.centerSlides !== undefined ? settings.centerSlides : defaults.centerSlides,
            onNavigate:
                settings.onNavigate !== undefined ? settings.onNavigate : defaults.onNavigate,
            lazyImages:
                settings.lazyImages !== undefined ? settings.lazyImages : defaults.lazyImages,
            responsive: settings.responsive,
            enableGestures:
                settings.enableGestures !== undefined
                    ? settings.enableGestures
                    : defaults.enableGestures,
        };

        if (settings.startAt > this.slideCount - 1) settings.startAt = defaults.startAt;
        if (settings.slidesPerView > this.slideCount)
            settings.slidesPerView = defaults.slidesPerView;

        return settings;
    };

    const initGestures = () => {
        this.panRecognizer = new Hammer.Pan({
            threshold: 0,
            pointers: 0,
            direction:
                this.settings.orientation === 'vertical'
                    ? Hammer.DIRECTION_VERTICAL
                    : Hammer.DIRECTION_HORIZONTAL,
        });

        this.sliderManager = new Hammer.Manager(this.sliderEl);
        this.sliderManager.add(this.panRecognizer);
        this.sliderManager.on('pan', onPanHandler);
    };

    const updateGestures = () => {
        if (this.slideCount > this.responsiveSettings.slidesPerView) {
            this.enable();
        } else {
            this.disable();
        }
    };

    //---- AUXILIARES -----//

    const getCssTranslateProperty = () =>
        this.settings.orientation === 'vertical' ? 'translateY' : 'translateX';

    const getContainer = () => {
        if (!this.container)
            this.container =
                typeof this.wrapperEl === 'string'
                    ? document.querySelector(this.wrapperEl)
                    : this.wrapperEl;
        return this.container;
    };

    const getSlidesContainer = () => {
        if (!this.sliderEl)
            this.sliderEl = this.container.querySelector(SLIDES_CONTAINER_CLASS + ' .slides');
        return this.sliderEl;
    };

    const getSlides = () => getSlidesContainer().querySelectorAll(SLIDE_CLASS);
    const getPaginationContainer = () => {
        if (!this.paginationContainer)
            this.paginationContainer = this.container.querySelector(
                '.' + PAGINATION_CONTAINER_CLASS
            );
        return this.paginationContainer;
    };

    const getPaginationBullets = () =>
        getPaginationContainer().querySelectorAll('.' + PAGINATION_BULLET_CLASS);

    //---- MÉTODOS PÚBLICOS ----//

    /** Inicializa com os parâmetros desejados
     *
     * @param settings: Pode conter:
     *  orientation ('horizontal' | 'vertical')
     *  loop (boolean)
     *  sensitivity (0 a 100)
     *  adaptOnResize (boolean)
     *  enableBullets (boolean)
     *  slidesPerView (number)
     *  startAt (number)
     *  responsive ({width: number, settings: object})
     */
    this.init = function (settings, callback) {
        if (getContainer() == null) {
            console.warn(`[hammer-slider] Container "${wrapperEl}" not found`);
            return;
        }

        // ordena as configurações de responsividade
        if (settings.responsive && settings.responsive.length > 1) {
            settings.responsive = settings.responsive.sort((a, b) => {
                return b.minWidth - a.minWidth;
            });
        }

        // Precisa tratar o objeto recebido para aplicar valores padrão em propriedades não contidas no objeto
        this.settings = validateSettings(settings, defaults);
        if (this.settings.responsive)
            for (let responsiveSettings of this.settings.responsive) {
                responsiveSettings.settings = validateSettings(
                    responsiveSettings.settings,
                    this.settings
                );
            }

        detectResponsiveSettings();

        // se tem só um filho não precisamos tratar o movimento
        /*if (getContainer().children.length <= 1) {
            console.warn("Container has only 1 child - ", wrapperEl);
            if (callback) callback(this);
            return;
        }*/

        prepareSlides();

        let intervalId;
        intervalId = setInterval(() => {
            if (getContainer() != null && getSlidesContainer() != null) {
                clearInterval(intervalId);
                this.cssTranslateProperty = getCssTranslateProperty();

                // todo precisa disso aqui?
                this.container = getContainer();
                this.sliderEl = getSlidesContainer();
                this.slideCount = getSlides().length;
                this.paginationContainer = getPaginationContainer();
                clonesCount = Math.min(this.slideCount, clonesCount);

                // todo tornar a orientação responsiva também
                if (this.settings.orientation === 'vertical')
                    this.sliderEl.parentElement.classList.add('slides-container-vertical');
                else this.sliderEl.parentElement.classList.remove('slides-container-vertical');

                initSlides();
                initArrows();
                initPagination();

                resizeSlides();
                if (this.responsiveSettings.adaptOnResize) {
                    window.addEventListener('resize', onWindowResize);
                }

                translateSlide();

                if (this.settings.enableGestures) {
                    initGestures();
                    updateGestures();
                }

                if (this.responsiveSettings.autoplayInterval > 0) {
                    initAutoplay();
                }

                getContainer().classList.add('slider-ready');
                if (callback) callback(this);
                reportNavigation();
            }
        }, 100);
    };

    this.disable = () => {
        if (this.panRecognizer) this.panRecognizer.set({ enable: false });
    };

    this.enable = () => {
        if (this.panRecognizer) this.panRecognizer.set({ enable: true });
    };

    this.forceResize = () => resizeSlides();

    /**
     * Navega até um slide.
     * @param index Índice do slide
     */
    this.goTo = (index) => {
        clearInterval(autoplayInterval);

        const slides = getSlides();
        const activeSlideOriginalIndex = getSlideOriginalIndex(this.activeSlideIndex, slides);

        if (index === activeSlideOriginalIndex) return;

        // Se for um slide à direita, tenta fazer o movimento à direita. Se for à esquerda, vice-versa.
        let slideIndex;
        if (index > activeSlideOriginalIndex) {
            slideIndex = findSlideIndexForward(index, slides);
            if (slideIndex === undefined) slideIndex = findSlideIndexBackward(index, slides);
        } else {
            slideIndex = findSlideIndexBackward(index, slides);
            if (slideIndex === undefined) slideIndex = findSlideIndexForward(index, slides);
        }

        doNavigation(slideIndex);
    };

    this.isReady = () => getContainer() && getContainer().classList.contains('slider-ready');
    this.getContainer = () => getContainer();

    this.destroy = () => {
        if (!this.isReady()) return;

        const slides = getContainer().querySelectorAll(
            this.settings.lazyImages ? '.slide-lazy-wrapper' : '.slide'
        );
        const originalElems = [];
        for (let slide of slides) {
            originalElems.push(slide.children[0].outerHTML);
        }
        getContainer().innerHTML = originalElems.reduce(
            (previous, current) => previous + current,
            ''
        );
        getContainer().classList.remove('slider-ready');
        getContainer().classList.remove('slider-lazy');
        this.sliderManager.destroy();
    };

    const findElementSlide = (el) => {
        if (!el) return null;

        let parent = el.parentNode;
        while (parent != null) {
            if (parent.className && parent.className.indexOf('slide slide-') >= 0) return parent;
            parent = parent.parentNode;
        }
    };

    this.lazyImageLoaded = false;
    this.onLazyImageLoad = (el) => {
        if (!this.settings) {
            console.warn('Not initialized', wrapperEl);
            return;
        }

        if (this.lazyImageLoaded || !this.settings.lazyImages) return;

        if (this.isReady()) {
            const slides = getSlides();
            const slide = findElementSlide(el);
            if (slide) {
                const width = el.offsetWidth;
                const height = el.offsetHeight;

                if (width > 0) {
                    let widthPercentage;
                    if (this.settings.fitSlidesToParent) widthPercentage = 100 / this.slideCount;
                    else
                        widthPercentage =
                            (slide.offsetWidth / getSlidesContainer().offsetWidth) * 100;
                    const paddingBottom = (height / width) * 100;

                    for (let slide of slides) {
                        slide.style.width = widthPercentage + '%';
                        slide.style.minWidth = widthPercentage + '%';
                        slide.querySelector('.slide-lazy-wrapper').style.paddingBottom =
                            paddingBottom + '%';
                    }

                    this.lazyImageLoaded = true;

                    getContainer().classList.add('slider-lazy');

                    translateSlide();
                }
            }
        }
    };
};

export default Slider;
