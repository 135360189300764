<template>
    <CrButton :color="color" class="btn-copy" @click="copy">{{
        hasCopied ? 'Copiado!' : label
    }}</CrButton>
</template>

<script>
    import { copyToClipboard } from '../../../script/clipboard';
    import CrButton from '../button/Button';

    export default {
        name: 'CopyButton',

        components: { CrButton },

        props: {
            color: {
                type: String,
                default: 'light',
            },
            label: {
                type: String,
                required: true,
            },
            data: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                hasCopied: false,
            };
        },

        watch: {
            hasCopied(newValue) {
                if (newValue) setTimeout(() => (this.hasCopied = false), 1000);
            },
        },

        methods: {
            copy() {
                copyToClipboard(this.data);
                this.hasCopied = true;
            },
        },
    };
</script>
