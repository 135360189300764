<template>
    <div class="d-flex">
        <form
            class="d-flex px-2 py-2"
            v-if="showLink"
            @submit.prevent="$emit('set-link')"
        >
            <input
                type="text"
                class="input-sm mr-2"
                :value="linkUrl"
                @change="$emit('update:linkUrl', $event.target.value)"
                placeholder="https://"
                ref="linkInput"
                @keydown.esc="$emit('hide-link')"
                style="min-width: 100px"
            />
            <div class="btn-group">
                <button class="btn btn-sm" @click="$emit('set-link')">
                    <i class="fas fa-check-circle" />
                </button>
                <button class="btn btn-sm" @click="$emit('reset-link')">
                    <i class="fas fa-times-circle" />
                </button>
            </div>
        </form>

        <template v-else>
            <template v-if="allowHeadings">
                <div
                    class="rich-text-field-bubble-btn"
                    :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                    @click="commands.heading({ level: 1 })"
                >
                    H1
                </div>
                <div
                    class="rich-text-field-bubble-btn"
                    :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                    @click="commands.heading({ level: 2 })"
                >
                    H2
                </div>
                <div
                    class="rich-text-field-bubble-btn"
                    :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                    @click="commands.heading({ level: 3 })"
                >
                    H3
                </div>
            </template>

            <div
                class="rich-text-field-bubble-btn"
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
            >
                <span class="fas fa-bold" aria-hidden="true" />
            </div>

            <div
                class="rich-text-field-bubble-btn"
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
            >
                <span class="fas fa-italic" aria-hidden="true" />
            </div>

            <div
                class="rich-text-field-bubble-btn"
                v-if="allowLink"
                :class="{ 'is-active': isActive.link() }"
                @click="$emit('show-link')"
            >
                <span class="fas fa-link" aria-hidden="true" />
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'RichTextFieldBar',

    props: {
        commands: Object,
        isActive: Object,
        menu: Object,
        getMarkAttrs: Function,
        allowLink: Boolean,
        allowHeadings: Boolean,
        showLink: Boolean,
        linkUrl: String,
    },

  watch: {
    showLink(to) {
      if(to) {
        this.$nextTick(() => {
          this.$refs.linkInput.focus()
        })
      }
    },
  },
}
</script>
