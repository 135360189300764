<template>
    <span>
        <span :style="`background-color: ${hexa}`" class="fabric-span" />
        {{ code || color }}
        <small v-if="code && color" class="text-secondary"> ({{ color }}) </small>
    </span>
</template>

<script>
    export default {
        name: 'FabricLabel',

        props: {
            hexa: {
                type: String,
                required: true,
            },
            code: {
                type: String,
            },
            color: {
                type: String,
            },
        },
    };
</script>
