<template>
    <li
        :class="{ 'input-option-multiline': !!subtitle }"
        class="text-left cursor-pointer"
        style="white-space: nowrap"
    >
        <slot v-if="$scopedSlots.default" :item="item" />
        <InputListItemContent v-else :icon="icon" :subtitle="subtitle" :title="title" />
    </li>
</template>

<script>
    import InputListItemContent from '../input-list-item-content/InputListItemContent';

    export default {
        name: 'InputListItem',

        components: { InputListItemContent },

        props: {
            item: [String, Object],
            title: [String, Number],
            subtitle: [String, Number],
            icon: String,
        },
    };
</script>
