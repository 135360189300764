import { cartService } from '../../../../service/resource';

const discountInitialState = { requests: 0 };

export default (store) => ({
    state: discountInitialState,

    addDiscount(name) {
        return new Promise((resolve, reject) => {
            this.state.requests++;

            const bodyFormData = new FormData();
            bodyFormData.append('discountName', name);
            bodyFormData.append('storeId', (initialScope.STORE || {}).id);

            cartService
                .addDiscount(initialScope.cart_id, bodyFormData)
                .then(() => {
                    this.state.requests--;
                    resolve();
                    store.cart.fetch();
                })
                .catch((response) => {
                    this.state.requests--;
                    reject(response);
                });
        });
    },

    removeDiscount(discountId, onFail) {
        this.state.requests++;

        cartService
            .removeDiscount(initialScope.cart_id, discountId)
            .then(store.cart.fetch)
            .catch(onFail)
            .then(() => this.state.requests--);
    },
});
