<template>
    <div id="header-mobile" class="hidden-sm-up header pos-relative bg-header-2">
        <div
            id="header-mobile-topbar-wrapper"
            class="header-topbar-wrapper pos-fixed pos-top pos-left pos-right bg-header-1"
        >
            <div class="d-flex flex-align-center pos-relative">
                <i id="header-drawer-icon"></i>
                <a
                    class="pos-absolute pos-center-x"
                    v-if="$scope.STORE && $scope.STORE.logo_url"
                    :href="`/${$scope.STORE.url}/`"
                    :title="$scope.STORE.name"
                >
                    <img
                        class="lazy center-block img-logo-scroll-store"
                        :data-src="`${$scope.CDN_IMAGES}/${$scope.STORE.logo_url}?auto=compress,format&q=65&w=300&h=70`"
                        :alt="$scope.STORE.name"
                    />
                </a>
                <a
                    v-else
                    :href="$scope.STORE ? `/${$scope.STORE.url}/` : '/'"
                    id="header-mobile-logo"
                    class="header-logo-scroll-up logo-up-text pos-absolute pos-center-x"
                    title="Uma Penca"
                ></a>
                <div id="header-cart-mobile" class="header-cart text-yanone ml-auto">
                    <div class="header-topbar-btn hidden-not-touch" @click.stop="show">
                        <div class="header-cart-icon-wrapper pos-relative mr-2">
                            <div class="header-cart-icon icon-cr icon-bag"></div>
                            <!-- "display: none" esconde antes de renderizar no vue -->
                            <span
                                class="header-cart-counter pos-absolute pos-right pos-top"
                                style="display: none; transform: translate(30%, -30%)"
                                v-show="cartProductsCount > 0"
                                :class="{
                                    'count-cart-max': cartProductsCount > 9,
                                }"
                            >
                                {{ cartProductsCount }}
                            </span>
                        </div>
                    </div>
                    <a class="header-topbar-btn hidden-touch" href="/carrinho">
                        <div class="header-cart-icon-wrapper pos-relative mr-2">
                            <div class="header-cart-icon icon-cr icon-bag"></div>
                            <!-- "display: none" esconde antes de renderizar no vue -->
                            <span
                                class="header-cart-counter pos-absolute pos-right pos-top"
                                style="display: none; transform: translate(30%, -30%)"
                                v-show="cartProductsCount > 0"
                                :class="{
                                    'count-cart-max': cartProductsCount > 9,
                                }"
                            >
                                {{ cartProductsCount }}
                            </span>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <!--        <div class="carrier-bf hidden-xs-up" style="position: fixed; background: #fec110; z-index: 15; height: 35px; padding: 0; width: 100%"-->
        <!--             v-if="$scope.CONFIG.VALOR_FRETE_GRATIS !== 'R$999999,99'">-->
        <!--          <div style="padding-right: 10px">-->
        <!--            <img src="https://uma-penca.imgix.net/universal/header/black-friday-frete-uma-penca-mobile.png?auto=compress,format" alt="Black Friday"-->
        <!--                 style="float: left; max-height: 100%;height: 34px;"><strong>Frete Grátis</strong> para todo o Brasil nas compras acima de R$ 150!-->
        <!--          </div>-->
        <!--        </div>-->

        <div
            id="header-products-wrapper"
            ref="headerProductsWrapper"
            :class="{
                open: slideMobileDrawerToRight,
                closed: !slideMobileDrawerToRight,
                'hidden-xs': hideMobileDrawer,
            }"
            class="pos-fixed w-screen h-screen pos-top"
            style="z-index: 18"
        >
            <div
                id="header-mobile-overlay"
                class="header-overlay"
                @click="slideMobileDrawerToRight = false"
            ></div>
            <div id="header-products" class="pos-fixed h-full flex-column">
                <div class="d-flex flex-align-center py-2 text-white bg-black px">
                    <a
                        class="header-mobile-logo header-logo-penca logo-up-penca mr-auto"
                        href="/"
                        title="Uma Penca"
                    ></a>
                    <a
                        v-if="!customer"
                        :href="`${$scope.URL_STORE_PREFIX}/entrar`"
                        class="text-center text-underline text-white"
                    >
                        <p class="h4 my-0">Entre ou cadastre-se</p>
                    </a>
                    <div v-else class="h4 logged my-0">
                        <a
                            :href="`${$scope.URL_STORE_PREFIX}/minha-conta/`"
                            class="text-underline text-white"
                        >
                            Minha Conta
                        </a>
                        &nbsp;|&nbsp;
                        <a
                            :href="`${$scope.URL_STORE_PREFIX}/logout`"
                            class="logged logout text-right text-underline text-white"
                        >
                            Sair
                        </a>
                    </div>
                </div>
                <!--header-items-mobile-->
                <nav
                    v-for="link in links"
                    :key="link.id"
                    class="header-drawer-items border-bottom border-dashed border-default-3"
                >
                    <a
                        :href="link.href"
                        :target="link.href[0] === 'h' ? '_blank' : ''"
                        class="h5 my-0"
                    >
                        {{ link.label }}
                    </a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderCart from '../HeaderCart';
    import { EventBus, lazyLoadInstance } from '../../../../common/common';
    import Hammer from 'hammerjs';

    const SHOW_MOBILE_MENU_EVENT = 'show-header-mobile-menu';

    export default {
        name: 'HeaderMobile',
        extends: HeaderCart,
        props: {
            links: {
                type: Array,
                required: true,
            },
            customer: Object,
        },
        data() {
            return {
                items: this.$scope.HEADER_ITEMS,
                isMounted: false,
                slideMobileDrawerToRight: false,
                hideMobileDrawer: true,
            };
        },

        created() {
            EventBus.$on(SHOW_MOBILE_MENU_EVENT, () => (this.slideMobileDrawerToRight = true));
        },

        mounted() {
            if (this.$refs.headerProductsWrapper) {
                const panHandler = (e) => {
                    if (e.isFinal && e.additionalEvent === 'panleft') {
                        this.slideMobileDrawerToRight = false;
                    }
                };

                const panRecognizer = new Hammer.Pan({
                    threshold: 0,
                    pointers: 0,
                    direction: Hammer.DIRECTION_HORIZONTAL,
                });

                this.sliderManager = new Hammer.Manager(this.$refs.headerProductsWrapper);
                this.sliderManager.add(panRecognizer);
                this.sliderManager.on('pan', panHandler);
            }

            this.$nextTick(() => lazyLoadInstance.update());

            this.isMounted = true;
        },

        watch: {
            slideMobileDrawerToRight(newValue, oldValue) {
                if (newValue && !oldValue) {
                    this.hideMobileDrawer = false;
                } else if (!newValue && oldValue) {
                    setTimeout(() => (this.hideMobileDrawer = true), 500);
                }
            },
        },
    };
</script>
