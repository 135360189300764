<template>
    <div :class="{ open }" class="collapsible">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'Collapsible',

        props: {
            open: {
                type: Boolean,
                required: true,
            },
        },
    };
</script>
